import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import { Div, P } from '@danfoss/etui-system-elements';
import { LoginLayout } from 'components/LoginLayout';
import { Icon, icons } from '@danfoss/etui-core';
import { APP_PROD_URL } from '../../constants';

const COUNTDOWN_START_SEC = 5;
const ONE_SEC = 1;

export function WebVersionRedirect() {
  const { t } = useTranslation();
  const theme = useTheme();

  const [timeLeft, setTimeLeft] = React.useState(COUNTDOWN_START_SEC);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      timeLeft && setTimeLeft(timeLeft - ONE_SEC);
      if (!timeLeft) window.location.href = APP_PROD_URL;
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);
  return (
    <LoginLayout>
      <Div
        p={`${theme.spacing.xxlg}px ${theme.spacing.xxlg * 2}px`}
        mt={`${theme.spacing.md}px`}
        bg={theme.palette.common.white}
        textAlign="center"
        lineHeight={2.25}
      >
        <Icon
          glyph={icons.CHECK_CIRCLE}
          size={128}
          color={theme.palette.success.main}
        />
        <P>{t('t3795')}</P>
        <P>{t('t3796')}</P>
        <P>{t('t3797', { timeLeft })}</P>
      </Div>
    </LoginLayout>
  );
}
