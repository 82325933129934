import { XML_DEVICE_COMBO, XML_DEVICE_STR } from '@danfoss/etui-sm-xml';
import { _ as _objectSpread2 } from '../_rollupPluginBabelHelpers-4e4a2de3.js';
import { DeviceGroupedType } from '../types/DeviceGrouped.js';
import { composeDeviceId } from './compose-device-id.js';
import { getIsSuctionDevice } from './get-is-suction-device.js';
import '@danfoss/etui-core';
import { getNormalizedSuction } from './get-normalized-suction.js';

function getSuctionGroups(deviceType, devices) {
  let start = 1;
  let suctionGroupItems;
  const suctionGroups = [];
  const suctionDevices = devices.filter(getIsSuctionDevice).map(getNormalizedSuction);
  for (const suction of suctionDevices) {
    const suctionGroup = _objectSpread2(_objectSpread2({}, suction), {}, {
      groupType: DeviceGroupedType.GROUP_DEVICE,
      id: composeDeviceId(suction),
      deviceType
    });
    suctionGroupItems = [];
    let suctionGroupItem;
    for (let i = start; i < devices.length; i++) {
      suctionGroupItem = _objectSpread2(_objectSpread2({}, devices[i]), {}, {
        groupType: DeviceGroupedType.GROUP_DEVICE,
        deviceType
      });
      if (suctionGroupItem.combo === XML_DEVICE_COMBO.COMBO_RACK) {
        start = i + 1; // skip upto and over this rack item next time
        if (i) {
          break;
        }
      } else if (getIsSuctionDevice(suctionGroupItem)) {
        if (suctionGroup.name !== suctionGroupItem.name || suctionGroup.arg1 !== suctionGroupItem.arg1 || suctionGroup.type !== suctionGroupItem.type || suctionGroup.host !== suctionGroupItem.host) {
          start = i + 1;
          break;
        }
      } else if (suctionGroupItem.type !== XML_DEVICE_STR.STR_TYPE_PACK && suctionGroupItem.type !== XML_DEVICE_STR.STR_TYPE_RACK) {
        if (suctionGroupItem.host === suctionGroup.host) {
          if (suctionGroupItem.arg2 === '' || suctionGroupItem.arg2 === undefined) {
            suctionGroups.push(suctionGroupItem);
          } else {
            suctionGroupItem.id = composeDeviceId(suctionGroupItem);
            suctionGroupItem.groupType = DeviceGroupedType.DEVICE_ONLY;
            suctionGroupItems.push(suctionGroupItem);
          }
        }
      }
    }
    suctionGroup.items = suctionGroupItems;
    suctionGroups.push(suctionGroup);
  }
  return suctionGroups;
}

export { getSuctionGroups };
