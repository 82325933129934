import * as React from 'react';
import { useTheme } from '@danfoss/etui-system';
import { Div } from '@danfoss/etui-system-elements';
import { Icon, icons, H3, H4, useResponsive } from '@danfoss/etui-core';
import { WEBSITE_NAME } from '../../config';
import * as S from './styles';

const STOREVIEW_WEB = 'StoreView Web®';

export const LoginLayout = React.memo(function Layout({ children }) {
  const theme = useTheme();
  const { screenIsAtMost } = useResponsive({
    md: parseInt(theme.breakpoints[1], 10),
  });
  const isMdView = screenIsAtMost('md');
  const AppNameComponent = isMdView ? H4 : H3;

  return (
    <S.Main>
      <Div textAlign="center" testId="danfoss-logo">
        <Icon
          glyph={icons.DANFOSS}
          viewBox="20 -100 350 350"
          size={isMdView ? 176 : 208}
          styles={{
            root: {
              color: theme.palette.common.white,
              mt: isMdView ? theme.spacing.xs * -5 : 0,
              mb: theme.spacing.xs * -5,
            },
          }}
        />
      </Div>
      <AppNameComponent
        color={theme.palette.common.white}
        fontWeight={theme.typography.fontWeightBold}
        testId="storeview-web-title"
      >
        {WEBSITE_NAME || STOREVIEW_WEB}
      </AppNameComponent>
      <Div>{children}</Div>
    </S.Main>
  );
});
