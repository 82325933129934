import {
  fetchXMLData,
  fetchXMLFile,
  User,
  XML_ACTION,
  XML_UPDATE_SW_CMD,
} from '@danfoss/etui-sm-xml';

export async function fetchFullClearedAlarmsLog(url: string, user: User) {
  const generateClearedAlarmsResponse = await fetchXMLData<{
    filename: string;
    access_area: string;
    return_code: string;
  }>({
    url,
    attributes: {
      action: XML_ACTION.UPDATE_SW,
      user: user.user,
      password: user.password,
      sw_upgrade_cmd: XML_UPDATE_SW_CMD.EXPORT_ALARMS,
    },
  });

  if (generateClearedAlarmsResponse.return_code !== '0') {
    throw new Error('Failed to export alarms');
  }

  const clearedAlarmsData = await fetchXMLFile({
    offset: 0,
    filename: generateClearedAlarmsResponse.filename,
    filepath: generateClearedAlarmsResponse.access_area,
    url,
    user: user.user,
    password: user.password,
    done: 0,
    decodeFile: false,
    time: Math.floor(Date.now() / 1000),
  });
  const csvBlob = new Blob([clearedAlarmsData], { type: 'text/csv' });

  return csvBlob;
}
