import '../constants/session-storage-constants.js';
import { XML_ACTION } from '../constants/xml-action-constant.js';
import '../constants/xml-tag-constant.js';
import '../types/Auth.js';
import '../types/Metadata.js';
import '../_rollupPluginBabelHelpers-4b44363a.js';
import 'xml2js';
import { buildXMLCommand } from '../helpers/xml-helpers.js';
import '../helpers/session-token-helpers.js';
import '../helpers/trim-url-ending.js';
import '../helpers/metadata-helpers.js';
import '../helpers/header-helpers.js';
import '../helpers/is-allowed-server-error.js';
import '../helpers/proxy-helpers.js';
import '../helpers/xml-error.js';
import '../proxy-config.js';
import { fetchXML } from './fetch-xml.js';

async function fetchXMLFile(_ref) {
  let {
    url,
    user,
    password,
    filename,
    filepath = null,
    offset,
    done,
    fileStream,
    decodeFile,
    directoryAttribute = 'directory',
    time
  } = _ref;
  // Initial fetch
  if (offset === 0 && done === 0) {
    fileStream = '';
  }

  // Means there is more to fetch
  if (done === 0) {
    const fileDownloadBody = buildXMLCommand({
      attributes: {
        user,
        password,
        action: XML_ACTION.GET_FILE,
        filename,
        [directoryAttribute]: filepath,
        offset,
        time,
        access_area: filepath
      }
    });
    const response = await fetchXML({
      url,
      body: fileDownloadBody
    });
    if (response && response.error === '0') {
      let newOffset;
      if (Array.isArray(response.offset)) {
        if (response.offset.length === 2) {
          const rawOffset = response.offset[1];
          newOffset = parseInt(rawOffset.replace(/'/g, ''), 10);
        } else {
          const rawOffset = response.offset[0];
          newOffset = parseInt(rawOffset.replace(/'/g, ''), 10);
        }
      } else {
        newOffset = parseInt(response.offset, 10);
      }
      const newDone = parseInt(response.done, 10);
      fileStream += response.encodedfile.b64;
      return fetchXMLFile({
        url,
        user,
        password,
        filename,
        filepath,
        offset: newOffset,
        done: newDone,
        fileStream,
        decodeFile,
        directoryAttribute
      });
    }
  } else {
    return decodeFile ? fileStream : atob(fileStream);
  }
  return null;
}

export { fetchXMLFile };
