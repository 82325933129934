import * as React from 'react';
import { Icon, icons, Table } from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { ColorPicker } from 'components/ColorPicker';
import { useTheme } from '@danfoss/etui-system';
import {
  ChartData,
  LegendConfig,
  LegendMap,
  Legend,
} from '../HistoryPage.types';
import { legendColumns } from '../utils/history-constants';
import { getMinMaxAvg } from '../utils/history-utils';

const digitalTableData: Legend = {
  min: null,
  max: null,
  avg: null,
};

export function HistoryLegend({
  data,
  onChartsToDrawChange,
  legendMap,
}: {
  data: ChartData[];
  onChartsToDrawChange: (id: string, config: Partial<LegendConfig>) => void;
  legendMap: LegendMap;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dataSource = React.useMemo(() => {
    return data.map(({ param, data: chartData }) => {
      const { min, max, avg } =
        param.digital === '1' ? digitalTableData : getMinMaxAvg(chartData);
      return {
        color: (
          <ColorPicker
            name={param.id}
            defaultValue={legendMap[param.id].color}
            onChange={color => onChartsToDrawChange(param.id, { color })}
          />
        ),
        icon: <Icon glyph={icons.GRAPH} />,
        name: param.name,
        interval: param.actual_sample_rate,
        min,
        max,
        avg,
        key: param.id,
      };
    });
  }, []); // adding dependencies here break the drawer toggle visibility checkbox

  return (
    <Div p={`${theme.spacing.xxlg}px`}>
      <Table
        rowSelection={{
          columnTitle: ' ',
          getCheckboxProps: ({ key }) => ({
            defaultChecked: true,
            testId: `${key}-toggle-chart-visibility`,
          }),
          onSelect: ({ key }, isVisible) =>
            onChartsToDrawChange(key, { isHidden: !isVisible }),
        }}
        columns={legendColumns.map(col => ({ ...col, title: t(col.title) }))}
        dataSource={dataSource}
        pagination={false}
        styles={{
          root: { m: `${theme.spacing.xlg}px` },
          th: { bg: 'transparent' },
          td: { bg: 'transparent' },
        }}
      />
    </Div>
  );
}
