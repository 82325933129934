import React from 'react';
import {
  icons,
  SpinnerSize,
  TreeViewItemContentDiv,
  TreeViewItemToggleDiv,
  TreeViewSubItemDiv,
  Checkbox,
  Spinner,
  ellipsis,
  Icon,
} from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { Div, DivProps } from '@danfoss/etui-system-elements';
import { useTheme } from '@danfoss/etui-system';
import {
  isHistoryObj,
  getUnit,
  formatHistoryTitle,
} from '../utils/history-utils';
import { getNestedItemsIds } from '../utils/get-nested-items-ids';

export function HistoryTreeItem({
  options,
  item,
  onFetchSubItems,
  checkIfShouldFetch,
  alreadyFetched,
  isAllHistoryParamsLoaded,
  formats,
  level = 0,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const isLoadingWithAllParams =
    !isAllHistoryParamsLoaded &&
    !alreadyFetched?.has(item.id) &&
    !isHistoryObj(item);

  const {
    showCheckBox,
    openSections,
    isRowSelected,
    getToggleProps,
    getCheckboxProps,
    checkedItems,
  } = options;

  const hasNestedItems = !!item.items?.length;
  const isOpen = openSections.includes(item.id);

  const { onClick: onToggle, onKeyDown, ...toggleProps } = getToggleProps();
  const { onChange: onCheckBoxChange } = getCheckboxProps();
  const isCheckboxDisabled =
    (hasNestedItems && !item.items.some(isHistoryObj)) ||
    (!hasNestedItems && !isHistoryObj(item));

  function handleClick(e) {
    onToggle(e, item);
  }

  const handleToggleClick = e => {
    isLoadingWithAllParams ? e.stopPropagation() : onToggle(e, item);
  };

  const hasAlreadyFetched = checkIfShouldFetch
    ? checkIfShouldFetch(item)
    : true;
  const shouldCheckboxBeShown = checkIfShouldFetch
    ? !hasAlreadyFetched && showCheckBox
    : showCheckBox;

  const getSubItem = () => {
    let subItem = '';
    if (!item || !item.ptype || !item.digital) {
      return '';
    }
    const units = item?.ptype ? ` ${getUnit(item.ptype, formats)}` : '';
    subItem = item.digital
      ? item.digital === '1'
        ? ` ${t('t3147')} ${units.length > 1 ? `/${units}` : ''}`
        : `${t('t3148')} ${units.length > 1 ? `/${units}` : ''}`
      : '';
    return subItem;
  };

  const deviceTitle = formatHistoryTitle(item);

  const renderSubitems = () => {
    if (!hasNestedItems || !isOpen) return null;

    return item.items.map(subitem => (
      <HistoryTreeItem
        key={subitem.id}
        options={options}
        item={subitem}
        level={level + 1}
        onFetchSubItems={onFetchSubItems}
        checkIfShouldFetch={checkIfShouldFetch}
        alreadyFetched={alreadyFetched}
        isAllHistoryParamsLoaded={isAllHistoryParamsLoaded}
        formats={formats}
      />
    ));
  };

  const nestedItemIds = getNestedItemsIds(item);
  const indeterminate =
    hasNestedItems &&
    checkedItems.some(checkedItem => nestedItemIds.includes(checkedItem));

  return (
    <Div flex={1}>
      <TreeViewItemContentDiv
        isRowSelected={isRowSelected(item)}
        onClick={handleClick}
        onKeyDown={e => onKeyDown(e, item)}
        {...item}
        showCheckBox={showCheckBox}
        cursor={isLoadingWithAllParams ? 'not-allowed' : 'pointer'}
      >
        <TreeViewItemToggleDiv
          lineHeight={0}
          hasNestedItems={hasNestedItems}
          isRowSelected={isRowSelected(item)}
          level={level}
          onClick={handleToggleClick}
          testId={`${item.id}-tree-item`}
          {...toggleProps}
        >
          <Icon
            glyph={icons.CHEVRON_DOWN}
            rotate={isOpen ? 0 : -90}
            size={16}
          />
        </TreeViewItemToggleDiv>
        {shouldCheckboxBeShown ? (
          <Div ml={`${theme.spacing.xxs}px`}>
            <Checkbox
              id={item.id}
              onChange={() => onCheckBoxChange(item)}
              disabled={isCheckboxDisabled}
              testId={`${item.id}-checkbox`}
              checked={checkedItems.includes(item.id)}
              indeterminate={indeterminate}
            />
          </Div>
        ) : null}
        <Div
          ml={`${theme.spacing.xs}px`}
          {...(ellipsis() as Partial<DivProps>)}
        >
          <Div position="relative" maxWidth="100%" lineHeight="normal">
            {deviceTitle && (
              <Div title={deviceTitle} {...(ellipsis() as any)}>
                {deviceTitle}
              </Div>
            )}
            <TreeViewSubItemDiv title={getSubItem()}>
              {getSubItem() && (
                <Div {...(ellipsis() as any)}>{getSubItem()}</Div>
              )}
            </TreeViewSubItemDiv>
          </Div>
        </Div>
        {isLoadingWithAllParams ? (
          <Div ml={`${theme.spacing.xs}px`}>
            <Spinner isInline={true} size={SpinnerSize.xSmall} />
          </Div>
        ) : null}
      </TreeViewItemContentDiv>
      {renderSubitems()}
    </Div>
  );
}
