import { getUnitNetworksByInitialUnit } from '@danfoss/etui-sm-xml';
import '../../_rollupPluginBabelHelpers-4e4a2de3.js';
import '@danfoss/etui-core';
import '../../constants/software-versions.js';
import '../../utils/get-software-version-prefix.js';
import '../../utils/get-is-matched-software-version.js';
import '../../actions/authorize-units.js';
import { fetchInitialUnit } from '../../actions/fetch-initial-unit.js';
import '../../actions/fetch-metadata-files.js';
import { fetchOtherUnits } from '../../actions/fetch-other-units.js';
import '../../utils/get-is-misc-device.js';
import { dublicateInitialUnitInfoForAddressWithPortNumber } from './utils/duplicate-session-info.js';

async function fetchUnits(_ref) {
  let {
    dispatch,
    protocol,
    user,
    url,
    showNotification
  } = _ref;
  try {
    const initialUnit = await fetchInitialUnit(url, user);
    const {
      validUnitNetworks,
      invalidUnitNetworks,
      internalUnits,
      externalUnits,
      isExternal,
      isDNS,
      isValidDomainName,
      isMisconfigured
    } = getUnitNetworksByInitialUnit(url, initialUnit, true);

    // Sometimes user enters unit address without port number (ex. - sm800a.ddns.net)
    // In many places we add port number to that address (ex. - sm800a.ddns.net:443)
    // Given this, we need to share the same sessionToken between these two addresses
    dublicateInitialUnitInfoForAddressWithPortNumber({
      url,
      protocol,
      initialUnit,
      unitNetworks: validUnitNetworks
    });
    const {
      subUnits,
      failedNetworks
    } = await fetchOtherUnits({
      dispatch,
      validUnitNetworks,
      initialUnit,
      user,
      protocol,
      showNotification
    });
    const units = [initialUnit, ...subUnits];
    const unitInfo = {
      validUnitNetworks,
      invalidUnitNetworks,
      internalUnits,
      externalUnits,
      isDNS,
      isExternal,
      isValidDomainName,
      isMisconfigured
    };
    dispatch({
      type: 'LOAD_UNITS_SUCCESS',
      payload: {
        units,
        failedNetworks,
        unitInfo
      }
    });
  } catch (error) {
    dispatch({
      type: 'LOAD_UNITS_ERROR',
      payload: error
    });
  }
}

export { fetchUnits };
