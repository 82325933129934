import '@danfoss/etui-sm-xml';
import '../../../_rollupPluginBabelHelpers-4e4a2de3.js';
import '@danfoss/etui-core';
import '../../../constants/session-storage-constants.js';
import '../../../constants/software-versions.js';
import '../../../utils/get-software-version-prefix.js';
import '../../../utils/get-is-matched-software-version.js';
import '../../../utils/get-language-code-by-name.js';
import '../../../utils/get-measurements-by-unit.js';
import '../../../utils/get-user-from-storage.js';
import '../../../actions/authorize-units.js';
import '../../../actions/fetch-initial-unit.js';
import '../../../actions/fetch-metadata-files.js';
import '../../../actions/fetch-other-units.js';
import { getFirstValidUnitUrls } from '../../../utils/get-first-valid-unit-urls.js';
import { getCurrentUnitAddress } from '../../../utils/get-current-unit.js';
import '../../../utils/get-is-misc-device.js';
import { useContext, useMemo, useCallback } from 'react';
import 'react-i18next';
import 'use-deep-compare-effect';
import '../../app/utils/get-xml-backend-ip.js';
import '../../app/utils/get-xml-backend-protocol.js';
import '../../app/utils/get-xml-proxy-config.js';
import '../../app/utils/set-xml-proxy-config.js';
import '../../app/app-context.js';
import { useApp } from '../../app/hooks/use-app.js';
import '../../app/hooks/use-xml-resource.js';
import '@danfoss/etui-sm-i18n';
import '../../auth/utils/logout-clean-up.js';
import '../../auth/utils/set-user-to-storage.js';
import '../../auth/auth-context.js';
import '../../auth/hooks/use-auth.js';
import 'styled-components';
import '../utils/duplicate-session-info.js';
import '../unit-actions.js';
import { UnitContext, UnitDispatchContext } from '../unit-context.js';

function useUnit() {
  const [state] = useApp();
  const context = useContext(UnitContext);
  const dispatch = useContext(UnitDispatchContext);
  if (!context) {
    throw new Error(`useUnit must be used within a UnitProvider`);
  }
  const {
    units,
    loadState,
    loadError,
    unitInfo,
    failedNetworks,
    unauthorizedNetworks
  } = context;
  const {
    validUnitNetworks,
    invalidUnitNetworks
  } = unitInfo || {};
  const allNetworks = useMemo(() => {
    const networks = validUnitNetworks && invalidUnitNetworks ? [...validUnitNetworks, ...invalidUnitNetworks] : validUnitNetworks ? [...validUnitNetworks] : invalidUnitNetworks ? [...invalidUnitNetworks] : [];
    return networks === null || networks === void 0 ? void 0 : networks.sort((a, b) => +a.addr - +b.addr);
  }, [validUnitNetworks, invalidUnitNetworks]);
  const currentUnit = getCurrentUnitAddress(units, allNetworks);
  const getFirstValidUrl = useCallback(unit => {
    var _getFirstValidUnitUrl;
    return (_getFirstValidUnitUrl = getFirstValidUnitUrls(units, unitInfo, state.protocol).find(u => (u === null || u === void 0 ? void 0 : u.addr) === (unit === null || unit === void 0 ? void 0 : unit.unit_addr))) === null || _getFirstValidUnitUrl === void 0 ? void 0 : _getFirstValidUnitUrl.url;
  }, [units, unitInfo]);
  const getFirstValidUnitNetwork = useCallback(unit => {
    var _getFirstValidUnitUrl2;
    return (_getFirstValidUnitUrl2 = getFirstValidUnitUrls(units, unitInfo, state.protocol).find(u => (u === null || u === void 0 ? void 0 : u.addr) === (unit === null || unit === void 0 ? void 0 : unit.unit_addr))) === null || _getFirstValidUnitUrl2 === void 0 ? void 0 : _getFirstValidUnitUrl2.network;
  }, [units, unitInfo]);
  const getUnitLabelText = useCallback(unit => {
    const hasUnitName = !!(unit !== null && unit !== void 0 && unit.unit_name);
    const unitLabel = `Unit ${unit === null || unit === void 0 ? void 0 : unit.unit_addr}${hasUnitName ? `: ${unit === null || unit === void 0 ? void 0 : unit.unit_name}` : ''}`;
    return unitLabel;
  }, [units, unitInfo]);
  return {
    units,
    unitInfo,
    currentUnit,
    allNetworks,
    failedNetworks,
    unauthorizedNetworks,
    loadState,
    loadError,
    getFirstValidUrl,
    getFirstValidUnitNetwork,
    dispatch,
    getUnitLabelText
  };
}

export { useUnit };
