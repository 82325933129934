import React from 'react';
import { Div, Span } from '@danfoss/etui-system-elements';
import { Button, icons, Notification } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

export interface HVACFinalAddDeviceProps {
  handleOnAddDevice: () => void;
  hvacDeviceAvailabilityToAdd: boolean;
}
export const HVACFinalAddDevice = ({
  handleOnAddDevice,
  hvacDeviceAvailabilityToAdd,
}: HVACFinalAddDeviceProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleDeviceAdd = () => {
    if (hvacDeviceAvailabilityToAdd) {
      handleOnAddDevice();
    } else {
      Notification.warning({
        message: t('t17'),
        description: t('t3800'),
        duration: 3,
        theme,
      });
    }
  };

  return (
    <Div style={{ display: 'inline-block' }} testId="hvac-add-device-div">
      <Span>
        <Button
          iconOnly={true}
          iconProps={{ glyph: icons.ADD, isIconLabel: true }}
          onClick={handleDeviceAdd}
        />
      </Span>
      <Span>{t('t3374')}</Span>
    </Div>
  );
};
