import { ConfigurationListItem } from '@danfoss/etui-sm-xml';

/**
 * Get is configuration list item is editable
 *
 * @export
 * @param {ConfigurationListItem} item
 * @returns {boolean}
 */
export function getIsEditableByItem(item: ConfigurationListItem): boolean {
  // item with edit control || action or toggle item
  if (parseInt(item?.eda || '0', 10) >= 1 || item?.ef !== '0') {
    return true;
  }
  return false;
}
