import * as React from 'react';
import {
  Button,
  Modal,
  Checkbox,
  ellipsis,
  useResponsive,
} from '@danfoss/etui-core';
import { Label, Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import { useForm, useFieldArray } from 'react-hook-form';
import { BaseEditProps } from './ConfigurationListItemEdit';
import { ConfigurationListitemEditInputAddon } from './ConfigurationListItemEditInputAddon';
import { ConfigurationListItemEditLoadingOverlay } from './ConfigurationListItemEditLoadingOverlay';
import { useFlash } from './hooks/useFlash';

function ConfigurationListItemEditCheckList({
  item,
  isAuthorized,
  onSave,
}: BaseEditProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const [flashSuccess, isSuccess] = useFlash(2000);
  const [flashError, isError] = useFlash(2000);
  const { screenIsAtMost } = useResponsive({
    sm: parseInt(theme.breakpoints[1], 10),
  });

  const isSmView = screenIsAtMost('sm', ['portrait', 'landscape']);

  const { control, register, setValue, watch, handleSubmit, formState, reset } =
    useForm({
      defaultValues: {
        'check-list': (item.listdata as unknown[]).map(({ iti }) => ({
          value: Number(item.iVal) & (1 << Number(iti)),
        })),
      },
    });

  const { fields } = useFieldArray({
    control,
    name: 'check-list',
  });

  React.useEffect(() => {
    if (isOpen) {
      reset({
        'check-list': (item.listdata as unknown[]).map(({ iti }) => ({
          value: Number(item.iVal) & (1 << Number(iti)),
        })),
      });
    }
  }, [isOpen, reset, item]);

  function getNewValue(result) {
    return result.reduce((res, { value }, i) => {
      if (value) {
        res += 1 << i;
      }
      return res;
    }, 0);
  }

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  async function onSubmit({ 'check-list': result }) {
    try {
      await onSave(item, {
        ival: getNewValue(result),
        fval: '',
      });
      flashSuccess();
      handleClose();
    } catch {
      flashError();
      reset();
    }
  }

  function cancel() {
    handleClose();
    reset();
  }

  const handleCheckBoxChange = (index, e) => {
    if (!e.target.checked) {
      setValue(`check-list[${index}].value`, 0);
    }
  };

  const iconXOffset = formState.isSubmitting ? 42 : 12;
  const { isSubmitting } = formState;

  return (
    <Div position="relative" testId="configuration-listitem-edit-cancel-button">
      <Modal
        isOpen={isOpen}
        onClose={cancel}
        header={<Modal.Header title={`${t('t902')}: ${item.name}`} />}
        fullPageSize={isSmView}
        actionButtons={[
          {
            variant: 'secondary',
            onClick: cancel,
            children: t('t45'),
            disabled: formState.isSubmitting,
          },
          {
            variant: 'primary',
            onClick: handleSubmit(onSubmit),
            children: t('t266'),
            disabled: formState.isSubmitting,
          },
        ]}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {fields.map((field, i) => (
            <Div
              display="flex"
              height="32px"
              alignItems="center"
              {...theme.typography.button}
              key={field.id}
            >
              <Checkbox
                disabled={isSubmitting}
                // @ts-ignore
                elRef={register()}
                name={`check-list[${i}].value`}
                id={field.id}
                checked={watch(`check-list[${i}].value`)}
                onChange={e => handleCheckBoxChange(i, e)}
                testId={`configuration-listitem-edit-days-checkbox-${i}`}
              />
              <Label
                htmlFor={field.id}
                color={theme.palette.text.secondary}
                {...theme.typography.label}
                ml={`${theme.spacing.xs}px`}
                {...(ellipsis() as any)}
              >
                {item.listdata[i]?._}
              </Label>
            </Div>
          ))}
        </form>
        {formState.isSubmitting ||
          (isError && (
            <ConfigurationListItemEditLoadingOverlay isError={isError} />
          ))}
      </Modal>
      <Button
        variant="secondary"
        small={true}
        onClick={handleOpen}
        disabled={!isAuthorized || isSubmitting}
        block={true}
        testId="configuration-listitem-edit-days-button"
      >
        {item.value}
      </Button>
      <Div
        display="flex"
        alignItems="center"
        position="absolute"
        top="0"
        right={['unset', `-${iconXOffset}px`]}
        left={[`-${iconXOffset}px`, 'unset']}
        height="42px"
      >
        <ConfigurationListitemEditInputAddon
          isSubmitting={isSubmitting}
          isSucceeded={isSuccess && !isError}
          isFailed={isError}
          styles={{
            inputAddon: {
              root: {
                top: isSubmitting ? '8px' : '4px',
              },
            },
          }}
        />
      </Div>
    </Div>
  );
}

export { ConfigurationListItemEditCheckList };
