import { XML_UPDATE_SW_CMD } from '@danfoss/etui-sm-xml';
import '../../../../../constants/session-storage-constants.js';
import '../../../../../constants/software-versions.js';
import '../../../../../constants/software-update-type.js';
import { SESSION_EXPIRY_CODE } from '../../../../../constants/index.js';
import { useState, useEffect } from 'react';
import { fetchUpdateSoftwareInstall } from '../actions/fetch-update-software-install.js';
import { delay } from '../utils/delay.js';
import '../actions/fetch-read-units.js';
import { UpdateSoftwareUnitStatus, fetchUpdateSoftwareUnitStatus } from '../actions/fetch-update-software-unit-status.js';
import { fetchUpdateSoftwareBackup } from '../actions/fetch-update-software-backup.js';

function useXmlSoftwareUpdate(url, user, updateCmd, shouldStart, backupCommand, isS55ToSM800A) {
  const [pending, setPending] = useState(false);
  const [value, setValue] = useState(null);
  const [isBackingUp, setIsBackingUp] = useState(false);
  const [error, setError] = useState(null);
  const [shouldLogOut, setShouldLogOut] = useState(false);
  useEffect(() => {
    const execute = async () => {
      let completed = false;
      setPending(true);
      if (backupCommand) {
        setIsBackingUp(true);
        try {
          await fetchUpdateSoftwareBackup(url, user, backupCommand);
          setIsBackingUp(false);
        } catch (e) {
          setError(e);
          setIsBackingUp(false);
          setPending(false);
          return;
        }
      }
      try {
        if (!isS55ToSM800A) {
          await fetchUpdateSoftwareInstall(url, user, updateCmd);
        }
        if (updateCmd === XML_UPDATE_SW_CMD.LOAD_VERIFIED_DATABASE) {
          fetchUpdateSoftwareInstall(url, user, XML_UPDATE_SW_CMD.RESTART_APP);
          setValue(UpdateSoftwareUnitStatus.DOWN);
          await delay(60000);
        }
        while (!completed) {
          // eslint-disable-next-line no-await-in-loop
          const status = await fetchUpdateSoftwareUnitStatus(url, user);
          if (status === UpdateSoftwareUnitStatus.DOWN) {
            completed = false;
            setValue(status);
          } else {
            completed = true;
            setValue(status);
            setPending(false);
          }
        }
      } catch (e) {
        if (+e.code === SESSION_EXPIRY_CODE) {
          setShouldLogOut(true);
          setValue(UpdateSoftwareUnitStatus.UP);
        } else {
          setError(e);
        }
        setPending(false);
      }
    };
    if (shouldStart) {
      execute();
    }
  }, [shouldStart]);
  const isInstalling = pending && value !== UpdateSoftwareUnitStatus.UP;
  const isInstalled = !pending && value === UpdateSoftwareUnitStatus.UP;
  return {
    pending,
    value,
    error,
    isBackingUp,
    isInstalled,
    isInstalling,
    shouldLogOut
  };
}

export { useXmlSoftwareUpdate };
