import { HistoryCfg } from '@danfoss/etui-sm-xml';

export enum Ptype {
  PTYPE_BPCT = 1, // DB_BYTE:  percent
  PTYPE_FLOAT2 = 15, // DB_FLOAT 2 byte: generic small float
  PTYPE_F2PCT = 16, // DB_FLOAT 2 byte: percent
  PTYPE_F2PSI = 17, // DB_FLOAT 2 byte: Pounds per Square Inch (pressure)
  PTYPE_F2DEGF = 18, // DB_FLOAT 2 byte: Degrees Fahrenheit   (temperature)
  PTYPE_F2DEGFD = 19, // DB_FLOAT 2 byte: Deg Fahrenheit Delta (temp change)
  PTYPE_F2PPM = 20, // DB_FLOAT 2 byte: Parts Per Million
  PTYPE_F2VOLT = 21, // DB_FLOAT 2 byte: Volts
  PTYPE_F2AMP = 22, // DB_FLOAT 2 byte: Amps
  PTYPE_F2KW = 23, // DB_FLOAT 2 byte: KiloWatts
  PTYPE_F2KWH = 24, // DB_FLOAT 2 byte: KiloWatt Hours
  PTYPE_F2HZ = 25, // DB_FLOAT 2 byte: Hertz
  PTYPE_F2GPM = 26, // DB_FLOAT 2 byte: Gallons per minute
  PTYPE_F2FPS = 27, // DB_FLOAT 2 byte: Feet per second
  PTYPE_F2PH = 28, // DB_FLOAT 2 byte: Acidity pH
  PTYPE_FLOAT4 = 30, // DB_FLOAT 4 byte: generic large float
  PTYPE_F4KW = 31, // DB_FLOAT 4 byte: KiloWatts
  PTYPE_F4KWH = 32, // DB_FLOAT 4 byte: Kilowatt hours
  PTYPE_SWPCT = 36, // DB_SWORD: percent
  PTYPE_IKWH = 41, // DB_SWORD: percent
  PTYPE_F2FC = 51, // DB_FLOAT 2 byte: FootCandles (fc)
}

export enum Dtype {
  DB_BYTE = 0, // unsigned char
  DB_WORD = 1, // unsigned short
  DB_FLOAT = 2, // float (FLOAT2 or FLOAT4, depending on context)
  DB_SWORD = 3, // signed short
  DB_INT = 4, // int
  DB_STR = 5, // char [] (with special NULL terminator handling)
  DB_ARR = 6, // BYTE [] (no NULL terminator handling)
  DB_FLOAT4 = 7, // MAX_DATA_TYPES- only used where '4 bytes' MUST BE explicit
  DB_FLOAT15 = 15, // received in log configuration response from unit
}

export interface UnitsConfig {
  isCelsius: boolean;
  isBar: boolean;
  isLux: boolean;
}

export enum AxisType {
  Pressure = 'pressure',
  Temperature = 'temperature',
  Percentage = 'percentage',
  Voltage = 'voltage',
  Current = 'current',
  Power = 'power',
  Energy = 'energy',
  Frequency = 'frequency',
  Light = 'light',
  Concentration = 'concentration',
  Flow = 'flow',
  Speed = 'speed',
  Acidity = 'acidity',
  Date = 'dateFormat',
  Time = 'timeFormat',
  Binary = 'binary',
  Other = 'other',
}

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

export interface ChartPoint {
  time: number;
  dataValue: number;
}

export interface ChartData {
  param: HistoryCfg;
  data: ChartPoint[];
}

export interface LegendConfig {
  color: string;
  isHidden: boolean;
}
export enum SampleRateConstants {
  ONEMINUTE = 60,
  ONEHOUR = 3600,
  ONEDAY = 86400,
}

export type LegendMap = Record<string, LegendConfig>;

export interface Legend {
  min: number;
  max: number;
  avg: number;
}
