import '@danfoss/etui-sm-xml';
import { _ as _objectSpread2 } from '../../../_rollupPluginBabelHelpers-4e4a2de3.js';
import '@danfoss/etui-core';
import '../../../constants/session-storage-constants.js';
import '../../../constants/software-versions.js';
import '../../../utils/get-language-code-by-name.js';
import '../../../utils/get-user-from-storage.js';
import '../../../utils/get-is-misc-device.js';
import { useContext } from 'react';
import 'use-deep-compare-effect';
import '../../app/utils/get-xml-backend-ip.js';
import '../../app/utils/get-xml-backend-protocol.js';
import '@danfoss/etui-sm-i18n';
import '../utils/logout-clean-up.js';
import '../utils/set-user-to-storage.js';
import { AuthContext, AuthDispatchContext } from '../auth-context.js';

function useAuthState() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  const {
    user,
    loginState,
    loginError,
    strongPassword,
    changePasswordResponse
  } = context;
  return {
    user,
    loginState,
    loginError,
    strongPassword,
    changePasswordResponse
  };
}
function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }
  return context;
}
function useAuth() {
  return _objectSpread2(_objectSpread2({}, useAuthState()), {}, {
    dispatch: useAuthDispatch()
  });
}

export { useAuth };
