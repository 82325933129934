import React from 'react';
import {
  ConfigurationListItem,
  fetchLayoutListByUnit,
  XML_REFRIG_COPY_TAB_INDEX,
} from '@danfoss/etui-sm-xml/';
import { useAuth } from '@danfoss/etui-sm/';
import { Notification } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system/';
import { useRefrigCopy } from '../../context';
import { getArray } from '../../../../../Configuration/utils';
import {
  CopyDevicesTableContent,
  CopyDevicesTableData,
} from '../../types/RefrigCopy.types';
import { withErrorHandled } from '../../../../../Configuration/utils/with-error-handled';
import { writeConfigurationListItem } from '../../../../../Configuration/actions';
import { REFRIG_SUCCESS_RESPONSE } from '../../../RefrigLayoutModal/types';
import {
  compareCopiedWithOldDevicesContent,
  processCopyDevicesTableData,
} from './actions';
import { CopyFlowPage } from '.';

interface CopyFlowContentProps {
  tableAddress: string;
}
export const CopyFlowContent = ({ tableAddress }: CopyFlowContentProps) => {
  const { unit, currentUnitUrl: url } = useRefrigCopy();

  const { user } = useAuth();

  const { t } = useTranslation();
  const theme = useTheme();
  const [isCopyTriggered, setIsCopyTriggered] = React.useState<boolean>(false);
  const {
    page,
    copyDevicesTableContent,
    isCopyDevicesDataFetched,
    copyDevicesListItems,
    setCopyDevicesListItems,
    setCopyDevicesTableContent,
    setCopyDevicesTableData,
    setIsCopyDevicesDataFetched,
    setIsCopyFinished,
  } = useRefrigCopy();

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const networkTableData = await fetchLayoutListByUnit([
          url,
          unit,
          tableAddress,
          XML_REFRIG_COPY_TAB_INDEX.COPY,
          user,
          page,
        ]);
        if (networkTableData?.items?.l?.length) {
          const tableContentData = getArray(networkTableData?.items?.l);
          const copyDevicesTableContent: CopyDevicesTableContent[] =
            processCopyDevicesTableData(tableContentData, t);
          const updatedCopyDevicesTableData: CopyDevicesTableData = {
            copyDevicesTableContent,
          };
          if (!copyDevicesTableContent.length) {
            setIsCopyDevicesDataFetched(true);
            setIsCopyFinished(true);
            return;
          }
          setCopyDevicesListItems(tableContentData);
          setCopyDevicesTableContent(copyDevicesTableContent);
          setCopyDevicesTableData(updatedCopyDevicesTableData);
        }
      } catch (error) {
        Notification.error({
          message: t('t17'),
          description: t('t3418'),
          duration: 3,
          theme,
        });
      }
    };
    if (!copyDevicesTableContent?.length) {
      fetch();
    }
  }, [page]);

  const copy = async (listItem: ConfigurationListItem) => {
    const response = await withErrorHandled(
      t,
      theme,
      writeConfigurationListItem,
    )(
      url,
      user,
      null,
      `${tableAddress}-${XML_REFRIG_COPY_TAB_INDEX.COPY}`,
      null,
      '0',
      '0',
      listItem,
      listItem.fVal,
      listItem.iVal,
      '',
    );
    if (response?.operation === REFRIG_SUCCESS_RESPONSE) {
      setIsCopyTriggered(true);
    }
  };

  React.useEffect(() => {
    const checkStatus = async () => {
      await checkCopyStatus();
    };
    if (isCopyTriggered) {
      checkStatus();
    }
  }, [isCopyTriggered]);

  const checkCopyStatus = async () => {
    try {
      const networkTableData = await fetchLayoutListByUnit([
        url,
        unit,
        tableAddress,
        XML_REFRIG_COPY_TAB_INDEX.COPY,
        user,
        page,
      ]);
      if (networkTableData?.items?.l?.length) {
        const copiedListItems = getArray(networkTableData?.items?.l);
        const copiedDeviesTableContent: CopyDevicesTableContent[] =
          processCopyDevicesTableData(copiedListItems, t);
        compareCopiedWithOldDevicesContent(
          copyDevicesTableContent,
          copiedDeviesTableContent,
          t,
        );
        const copiedCopyDevicesTableData: CopyDevicesTableData = {
          copyDevicesTableContent: copiedDeviesTableContent,
        };
        setCopyDevicesTableContent(copiedDeviesTableContent);
        setCopyDevicesTableData(copiedCopyDevicesTableData);
        const copiedDevicesLength = copyDevicesTableContent.filter(
          content => content.isCopying,
        ).length;
        if (copiedDevicesLength) {
          await checkCopyStatus();
        } else {
          setIsCopyFinished(true);
        }
      }
    } catch (error) {
      Notification.error({
        message: t('t17'),
        description: t('t3419'),
        duration: 3,
        theme,
      });
    }
  };

  React.useEffect(() => {
    const copyDevices = async () => {
      const copyListItem = copyDevicesListItems[1];
      await copy(copyListItem);
    };
    if (isCopyDevicesDataFetched && copyDevicesListItems?.length) {
      copyDevices();
    }
  }, [isCopyDevicesDataFetched]);
  return <CopyFlowPage />;
};
