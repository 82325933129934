import React from 'react';
import { useXmlResource } from '@danfoss/etui-sm/context/app';
import { useAuth } from '@danfoss/etui-sm/context/auth';
import {
  fetchConfigurationTableByUnit,
  fetchLayoutListByUnit,
} from '@danfoss/etui-sm-xml';
import useSWR from 'swr';
import { useRefrigLayout } from '../modules/RefrigLayoutModal/context';

function useRefrigLayoutContent(
  index: string,
  tableAction: string,
  tableAddress?: string,
) {
  const { url } = useXmlResource();
  const { user } = useAuth();
  const { page, setPage, setMultipage, unit } = useRefrigLayout();

  const { data: refrigLayoutHelp } = useSWR(
    () => (!tableAddress ? [url, unit, tableAction, '', user] : null),
    fetchConfigurationTableByUnit,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
  );

  const tabsHelpTexts = refrigLayoutHelp?.items?.tabitem;

  const tableaddress = refrigLayoutHelp && refrigLayoutHelp?.tableaddress;

  const networkTableAddress =
    tableAddress || tableaddress?.filter(address => address !== '')[0];

  const {
    data: networkTableData,
    isLoading,
    isValidating,
  } = useSWR(
    () =>
      networkTableAddress
        ? [url, unit, networkTableAddress, index, user, page]
        : null,
    fetchLayoutListByUnit,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      revalidateIfStale: true,
    },
  );

  React.useEffect(() => {
    if (networkTableData) {
      if (networkTableData.multipage) {
        setMultipage(networkTableData.multipage);
      } else {
        setPage('0');
      }
    }
  }, [networkTableData]);

  const tableContentData = networkTableData?.items?.l;

  const stepContents = networkTableData?.tabs?.items?.tabitem;

  return {
    tableContentData,
    tabsHelpTexts,
    isLoading,
    stepContents,
    networkTableAddress,
    isValidating,
  };
}

export { useRefrigLayoutContent };
