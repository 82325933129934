import { useAuth } from '@danfoss/etui-sm/context/auth';
import {
  fetchConfigurationTableByUnit,
  fetchLayoutListByUnit,
} from '@danfoss/etui-sm-xml';
import useSWR from 'swr';
import { useRefrigCopy } from '../modules/RefrigCopyModal/context';

function useRefrigCopyContent(
  index: string,
  tableAction: string,
  tableAddress?: string,
  page?: string,
) {
  const { user } = useAuth();
  const { unit, currentUnitUrl: url } = useRefrigCopy();

  const { data: refrigCopyHelp } = useSWR(
    () => (!tableAddress ? [url, unit, tableAction, '', user] : null),
    fetchConfigurationTableByUnit,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
  );

  const tabsHelpTexts = refrigCopyHelp?.items?.tabitem;

  const tableaddress = refrigCopyHelp && refrigCopyHelp?.tableaddress;

  const networkTableAddress =
    tableAddress || tableaddress?.filter(address => address !== '')[0];

  const {
    data: networkTableData,
    isLoading,
    isValidating,
  } = useSWR(
    () =>
      networkTableAddress
        ? [url, unit, networkTableAddress, index, user, page]
        : null,
    fetchLayoutListByUnit,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      revalidateIfStale: true,
    },
  );

  const tableContentData = networkTableData?.items?.l;

  const stepContents = networkTableData?.tabs?.items?.tabitem;
  return {
    tableContentData,
    tabsHelpTexts,
    isLoading,
    stepContents,
    networkTableAddress,
    isValidating,
    networkTableData,
  };
}

export { useRefrigCopyContent };
