import * as React from 'react';
import { Div } from '@danfoss/etui-system-elements';
import { Button } from '@danfoss/etui-core';
import { StagingPatternTableData } from './types';
import { ConfigurationListItemEditStagingPatternUnloaders } from './ConfigurationListItemEditStagingPatternUnloaders';
import { animatedCompressorSvg, compressorSvg } from './utils';

export interface ConfigurationListItemEditStagingPatternCompressorsProps {
  stagingPattern: StagingPatternTableData;
  rowIndex: number;
  handleCompressorClick: (rowIndex: number, compIndex: number) => void;
  handleUnloaderClick: (
    rowIndex: number,
    compIndex: number,
    unloaderIndex: number,
  ) => void;
}

export const ConfigurationListItemEditStagingPatternCompressors = ({
  stagingPattern,
  rowIndex,
  handleCompressorClick,
  handleUnloaderClick,
}: ConfigurationListItemEditStagingPatternCompressorsProps) => {
  return (
    <>
      {stagingPattern.compressorUnloaderVisibiltyStatus.map(
        (visibility, compIndex) => {
          const visibilities: boolean[] = Object.values(
            visibility.unloadersVisibility,
          ).filter(value => value === true);
          const statuses: boolean[] = Object.values(
            visibility.compressorUnloadersStatus,
          ).slice(1);

          const isCompressorActive =
            visibility.compressorUnloadersStatus.compressorStatus;

          return (
            <Div testId={`compressor-unloader-${compIndex}`} display="flex">
              <Div testId={`compressor-${compIndex}`}>
                <Button
                  testId={`compressor-button-${compIndex}`}
                  onClick={() => {
                    handleCompressorClick(rowIndex, compIndex);
                  }}
                >
                  <Div
                    dangerouslySetInnerHTML={{
                      __html: isCompressorActive
                        ? animatedCompressorSvg
                        : compressorSvg,
                    }}
                  />
                </Button>
              </Div>
              <Div
                testId={`unloader-${compIndex}`}
                ml="-12px"
                mt={
                  visibilities.length === 4
                    ? '4px'
                    : visibilities.length === 3
                    ? '24px'
                    : visibilities.length === 2
                    ? '44px'
                    : '64px'
                }
              >
                <ConfigurationListItemEditStagingPatternUnloaders
                  visibilities={visibilities}
                  statuses={statuses}
                  rowIndex={rowIndex}
                  compIndex={compIndex}
                  handleUnloaderClick={handleUnloaderClick}
                />
              </Div>
            </Div>
          );
        },
      )}
    </>
  );
};
