import React from 'react';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { useRefrigLayout } from '../../context';
import { DEVICE_TYPE } from '../../types/DiscoveryPage.types';
import { getTypeBasedOnDeviceType } from '../../utils';
import { LayoutFlowTitle } from '../LayoutContent';
import { getDeviceModelList } from '../../../../hooks';
import { CircuitsTable } from './CircuitsTable';

export const CircuitsFlowContent = () => {
  const { t } = useTranslation();

  const {
    discoveryTableData,
    setCircuitsTableData,
    setCircuitTypeModelList,
    unit,
  } = useRefrigLayout();

  const type: number = getTypeBasedOnDeviceType(1);

  const { deviceModelList } = getDeviceModelList(type, unit);

  React.useEffect(() => {
    if (deviceModelList) {
      setCircuitTypeModelList(deviceModelList);
    }
  }, [deviceModelList]);

  const filterCircuitsData = () => {
    const filteredCircuitsData = discoveryTableData?.filter(
      device =>
        device.deviceType === DEVICE_TYPE.CIRCUIT ||
        device.deviceType === DEVICE_TYPE.GEN_CIRCUIT,
    );

    setCircuitsTableData(filteredCircuitsData);
  };
  return (
    <>
      <Div mt="10px" testId="circuit-help-text">
        <LayoutFlowTitle
          title={t('t481')}
          helpText={t('t3372')}
          subTitle={t('t3373')}
        />
      </Div>
      <CircuitsTable filterCircuits={filterCircuitsData} />
    </>
  );
};
