import React from 'react';
import { ButtonProps } from '@danfoss/etui-core';
import {
  ConfigurationListDataArray,
  ConfigurationListItem,
  Unit,
} from '@danfoss/etui-sm-xml';
import {
  AlarmsTableContent,
  AlarmsTableData,
  CopyDevicesTableContent,
  CopyDevicesTableData,
  DevicesTableContent,
  DevicesTableData,
  GraphTableData,
  ParamTableData,
  RefrigCopySteps,
  SourceAlarmTableContent,
  SourceAlarmTableData,
  SourceAlarmTablePageContent,
  SourceGraphTableContent,
  SourceGraphTableData,
  SourceGraphTablePageContent,
  ParametersTableContent,
  GraphTableContent,
} from '../types/RefrigCopy.types';

export type RefrigCopyFlow =
  | 'sourceController'
  | 'sourceSettings'
  | 'sourceAlarms'
  | 'sourceGraph'
  | 'files'
  | 'parameters'
  | 'alrms'
  | 'graph'
  | 'devices'
  | 'copy';

export type Step =
  | 'sourceController'
  | 'sourceSettings'
  | 'sourceAlarms'
  | 'sourceGraph'
  | 'files'
  | 'parameters'
  | 'alrms'
  | 'graph'
  | 'devices'
  | 'copy';

export type AllPageType =
  | SourceGraphTableContent
  | ParametersTableContent
  | AlarmsTableContent
  | GraphTableContent;

export interface RefrigCopyContextProps {
  flow: RefrigCopyFlow;
  setFlow: (newFlow: RefrigCopyFlow) => void;
  alarmListGroup?: string[];
  setAlarmListGroup?: (listGroup: string[]) => void;
  step?: number;
  buttonsByStep: { [key: string]: ButtonProps[] };
  nextStep?: Step;
  stepNumber?: number;
  totalStepsNumber?: number;
  setStep: (step: number) => void;
  setButtons: (newButtons: ButtonProps[]) => void;
  isOfflineProgramming?: boolean;
  setIsOfflineProgramming?: (isOfflineProgramming: boolean) => void;
  activeStepNumber?: number;
  setActiveStepNumber?: (activeStepNumber: number) => void;
  refrigSteps?: RefrigCopySteps[];
  setRefrigSteps?: (refrigSteps: RefrigCopySteps[]) => void;
  unit: Unit;
  setUnit: (unit: Unit) => void;
  currentUnitUrl: string;
  isTableRefreshing?: boolean;
  setIsTableRefreshing?: (isTableRefreshing: boolean) => void;
  networkListData: any;
  setNetworkListData: (networkListData: any) => void;
  sourceGraphTableData: SourceGraphTableData;
  setSourceGraphTableData: (sourceGraphTableData: SourceGraphTableData) => void;
  sourceAlarmTableData: SourceAlarmTableData;
  setSourceAlarmTableData: (sourceAlarmTableData: SourceAlarmTableData) => void;
  page: string;
  setPage: (page: string) => void;
  searchValue: string;
  setSearchValue: (searchValue: string) => void;
  onSetSearchValue: (searchValue: string) => void;
  searchMode: boolean;
  setSearchMode: (searchMode: boolean) => void;
  multipage: string;
  setMultipage: (multipage: string) => void;
  pageValues: number[];
  setPageValues: (pageValues: number[]) => void;
  allPageSourceAlarmData: SourceAlarmTableContent[];
  setAllPageSourceAlarmData: (
    allPageSourceAlarmData: SourceAlarmTableContent[],
  ) => void;
  allPageData: AllPageType[];
  setAllPageData: (allPageData: AllPageType[]) => void;
  isAllPageDataFetched: boolean;
  setIsAllPageDataFetched: (isAllPageDataFetched: boolean) => void;
  sourceGraphTablePageContent: SourceGraphTablePageContent[];
  setSourceGraphTablePageContent: (
    sourceGraphTablePageContent: SourceGraphTablePageContent[],
  ) => void;
  isPageDataFetched: boolean;
  setIsPageDataFetched: (isPageDataFetched: boolean) => void;
  saveCompleted: boolean;
  setSaveCompleted: (saveCompleted: boolean) => void;
  selectedRowIndex: number;
  setSelectedRowIndex: (selectedRowIndex: number) => void;
  sourceAlarmTablePageContent: SourceAlarmTablePageContent[];
  setSourceAlarmTablePageContent: (
    sourceAlarmTablePageContent: SourceAlarmTablePageContent[],
  ) => void;
  alarmLevelList: ConfigurationListDataArray;
  setAlarmLevelList: (alarmLevelList: ConfigurationListDataArray) => void;
  enableAllAlarms: boolean;
  setEnableAllAlarms: (enableAllAlarms: boolean) => void;
  devicesTableContent: DevicesTableContent[];
  setDevicesTableContent: (devicesTableContent: DevicesTableContent[]) => void;
  devicesTableData: DevicesTableData;
  setDevicesTableData: (deviceTableData: DevicesTableData) => void;
  devicesListItems: ConfigurationListItem[];
  setDevicesListItems: (devicesListItems: ConfigurationListItem[]) => void;
  selectAll: ConfigurationListItem[];
  setSelectAll: (selectAll: ConfigurationListItem[]) => void;
  copyDevicesListItems: ConfigurationListItem[];
  setCopyDevicesListItems: (
    copyDevicesListItems: ConfigurationListItem[],
  ) => void;
  copyDevicesTableContent: CopyDevicesTableContent[];
  setCopyDevicesTableContent: (
    copyDevicesTableContent: CopyDevicesTableContent[],
  ) => void;
  copyDevicesTableData: CopyDevicesTableData;
  setCopyDevicesTableData: (copyDevicesTableData: CopyDevicesTableData) => void;
  isCopyDevicesDataFetched: boolean;
  setIsCopyDevicesDataFetched: (isCopyDevicesDataFetched: boolean) => void;
  isCopyFinished: boolean;
  setIsCopyFinished: (isCopyFinished: boolean) => void;
  isCopyControllerSelected: boolean;
  setIsCopyControllerSelected: (isCopyControllerSelected: boolean) => void;
  isCopyControllerLoading: boolean;
  setIsCopyControllerLoading: (isCopyControllerLoading: boolean) => void;
  isLoadingConfigFile: boolean;
  setIsLoadingConfigFile: (isLoadingConfigFile: boolean) => void;
  paramTableData: ParamTableData;
  setParamTableData: (paramTableData: any) => void;
  alarmsTableData: AlarmsTableData;
  setAlarmsTableData: (alarmsTableData: AlarmsTableData) => void;
  graphTableData: GraphTableData;
  setGraphTableData: (graphTableData: GraphTableData) => void;
  isEmptyCopyDevicesTableData: boolean;
  setIsEmptyCopyDevicesTableData: (
    isEmptyCopyDevicesTableData: boolean,
  ) => void;
  isHvac: boolean;
  setIsHvac: (isHvac: boolean) => void;
  isBackToSourceController: boolean;
  setIsBackToSourceController: (isBackToSourceController: boolean) => void;
  isAK2: boolean;
  setIsAK2: (isAK2: boolean) => void;
  selectedDeviceBpidx: string;
  setSelectedDeviceBpidx: (selectedDeviceBpidx: string) => void;
}

const RefrigCopyContext = React.createContext<RefrigCopyContextProps>(null);

export { RefrigCopyContext };
