import * as React from 'react';
import { getUnitUrl, fetchLayoutListByUnit } from '@danfoss/etui-sm-xml';
import { useXmlResource, useAuth } from '@danfoss/etui-sm/';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import { Div } from '@danfoss/etui-system-elements';
import { ConfigurationItemContentMenuPageSelect } from 'pages/ConfigurationPage';
import { getIsSkippi200 } from '../../../../utils';
import { writeConfigurationListItem } from '../../../../../Configuration/actions';
import { withErrorHandled } from '../../../../../Configuration/utils/with-error-handled';
import { useSaveWithRevalidate } from '../../../../../Configuration/components/ConfigurationItemContentList/hooks/useSaveWithRevalidate';
import { useRefrigLayout } from '../../context';
import { LayoutItemVirtualizedList as VirtualizedList } from './LayoutItemVirtualizedList';

interface LayoutItemContentListProps {
  tableAddress: string;
}

function LayoutItemContentList({ tableAddress }: LayoutItemContentListProps) {
  const { user } = useAuth();
  const { url: xmlBackendURL } = useXmlResource();
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    networkListData,
    setNetworkListData,
    setSkipPI200,
    unit,
    page,
    setPage,
    multipage,
    setMultipage,
  } = useRefrigLayout();

  const hasMultiplePages = multipage && multipage !== '0';
  const menuId = tableAddress;

  const handleSave = React.useCallback(
    async (listItem, { value, ival, fval = null } = {}) => {
      const response = await withErrorHandled(
        t,
        theme,
        writeConfigurationListItem,
      )(
        getUnitUrl(xmlBackendURL, unit),
        user,
        null,
        menuId,
        null,
        0,
        0,
        listItem,
        value,
        ival,
        fval,
      );
      return response;
    },
    [unit, user, xmlBackendURL],
  );

  const revalidate = async () => {
    const result = await fetchLayoutListByUnit([
      getUnitUrl(xmlBackendURL, unit),
      unit,
      tableAddress,
      '1',
      user,
    ]);
    if (result?.items?.l) {
      setNetworkListData(result?.items?.l);
      const isSkippi200 = getIsSkippi200(result?.items?.l);
      setSkipPI200(isSkippi200);
      setMultipage(result?.multipage);
    }
  };
  const { execute: executeOnHandleSave } = useSaveWithRevalidate(
    handleSave,
    revalidate,
    1000,
    false,
  );
  const handleModalPageChange = (newPage: string) => {
    setPage(newPage);
  };

  return (
    <Div height="100%">
      {hasMultiplePages && (
        <Div border={`1px solid ${theme.palette.divider}`} borderRight={'none'}>
          <ConfigurationItemContentMenuPageSelect
            key={'pageSelect'}
            selectedPage={page}
            isFullWidth={true}
            multipage={multipage}
            onPageChange={handleModalPageChange}
          />
        </Div>
      )}
      <VirtualizedList
        list={networkListData}
        tabIsEditable={true}
        onSave={executeOnHandleSave}
        tableAddress={tableAddress}
      />
    </Div>
  );
}

export { LayoutItemContentList };
