import '@danfoss/etui-sm-xml';
import '../../../../../_rollupPluginBabelHelpers-4e4a2de3.js';
import '@danfoss/etui-core';
import '../../../../../constants/session-storage-constants.js';
import '../../../../../constants/software-versions.js';
import '../../../../../constants/software-update-type.js';
import '../../../../../constants/index.js';
import '../../../../../utils/get-software-version-prefix.js';
import '../../../../../utils/get-is-matched-software-version.js';
import '../../../../../utils/get-language-code-by-name.js';
import '../../../../../utils/get-measurements-by-unit.js';
import '../../../../../utils/get-user-from-storage.js';
import '../../../../../utils/compare-software-versions.js';
import '../../../../../utils/get-version-from-filename.js';
import '../../../../../actions/authorize-units.js';
import '../../../../../actions/fetch-initial-unit.js';
import '../../../../../actions/fetch-metadata-files.js';
import '../../../../../actions/fetch-other-units.js';
import '../../../../../utils/get-is-misc-device.js';
import React from 'react';
import 'react-i18next';
import 'swr';
import '@danfoss/etui-system';
import '../../../../../context/modal/modal-context.js';
import '../../../../../context/modal/use-modal.js';
import 'use-deep-compare-effect';
import '../../../../../context/app/utils/get-xml-backend-ip.js';
import '../../../../../context/app/utils/get-xml-backend-protocol.js';
import '../../../../../context/app/utils/get-xml-proxy-config.js';
import '../../../../../context/app/utils/set-xml-proxy-config.js';
import '../../../../../context/app/app-context.js';
import '../../../../../context/app/hooks/use-app.js';
import '../../../../../context/app/hooks/use-xml-resource.js';
import '@danfoss/etui-sm-i18n';
import '../../../../../context/auth/utils/logout-clean-up.js';
import '../../../../../context/auth/utils/set-user-to-storage.js';
import '../../../../../context/auth/auth-context.js';
import '../../../../../context/auth/hooks/use-auth.js';
import 'styled-components';
import '../../../../../context/unit/utils/duplicate-session-info.js';
import '../../../../../context/unit/unit-actions.js';
import '../../../../../context/unit/unit-context.js';
import 'lodash.isequal';
import 'react-device-detect';
import 'featurehub-javascript-client-sdk';
import '../../../../../components/SwrGlobal/SwrGlobal.js';
import { useAsync } from '../../../../../hooks/use-async.js';
import 'match-sorter';
import '../context/SoftwareUpdateContext.js';
import '../context/hooks/use-multi-unit-update.js';
import '../../../../../use-update-type-values-15196355.js';
import 'moment';
import { fetchUpdateSoftwareSecurityCheck } from '../actions/fetch-update-software-security-check.js';
import { fetchUpdateSoftwareUnzipFile } from '../actions/fetch-update-software-unzip-file.js';
import { fetchUpdateSoftwareCheck, RETURN_CODE_DANUX_UPDATE_NEEDED, RETURN_CODE_NO_DOWNGRADE_ALLOWED } from '../actions/fetch-update-software-check.js';
import { fetchDbS55Load } from '../actions/fetch-db-s55-load.js';
import { fetchUpdateSoftwareDanuxCheck } from '../actions/fetch-update-software-danux-check.js';
import '../utils/read-file-async.js';
import '../actions/fetch-upload-file.js';
import { useXmlFileUpload } from './use-xml-file-upload.js';
import 'jszip';
import 'path';
import '@danfoss/etui-sm';
import '@danfoss/etui-sm/actions';

function getStepData(pending, value, error) {
  const isSucceeded = !pending && !!value && !error;
  const isFailed = !pending && !value && !!error;
  return {
    isSucceeded,
    isFailed
  };
}
function useSoftwareCheck(url, user, file, isDBUpdateFlow, checkPrerequisites, startCheck, isS55ToSM800A, checkLPKVersion) {
  if (checkLPKVersion === void 0) {
    checkLPKVersion = false;
  }
  const {
    progress,
    pending: isUploading,
    value,
    error
  } = useXmlFileUpload(url, user, file, isDBUpdateFlow, startCheck, isS55ToSM800A);
  if (isS55ToSM800A) {
    const {
      execute: executeDbS55Load,
      pending: isDbS55Loading,
      value: dbS55LoadData,
      error: dbS55LoadError
    } = useAsync(() => fetchDbS55Load(url, user), false);
    const {
      isSucceeded: isUploadingSucceeded,
      isFailed: isUploadingFailed
    } = getStepData(isUploading, value, error);
    React.useEffect(() => {
      let timer = null;
      if (!isUploading && isUploadingSucceeded && !isUploadingFailed) {
        timer = setTimeout(() => {
          executeDbS55Load();
        }, 500);
      }
      return () => clearTimeout(timer);
    }, [isUploading, isUploadingSucceeded, isUploadingFailed]);
    const {
      isSucceeded: isDbS55LoadSucceeded,
      isFailed: isDbS55LoadFailed
    } = getStepData(isDbS55Loading, dbS55LoadData, dbS55LoadError);
    const isLoadS55ToSM800ACompleted = isUploadingSucceeded && isDbS55LoadSucceeded;
    const isLoadS55ToSM800AFailed = isUploadingFailed || isDbS55LoadFailed;
    return {
      isUploading,
      isDbS55Loading,
      isUploadingFailed,
      isDbS55LoadFailed,
      isUploadingSucceeded,
      isDbS55LoadSucceeded,
      isLoadS55ToSM800ACompleted,
      isLoadS55ToSM800AFailed,
      fileUploadProgress: progress
    };
  }
  const {
    execute: executeSecurityCheck,
    pending: isSecurityChecking,
    value: securityCheckData,
    error: securityCheckError
  } = useAsync(() => fetchUpdateSoftwareSecurityCheck(url, user, isDBUpdateFlow), false);
  const {
    execute: executeUnzipFile,
    pending: isUnzippingFile,
    value: unzipFileData,
    error: unzipFileError
  } = useAsync(() => fetchUpdateSoftwareUnzipFile(url, user, isDBUpdateFlow), false);
  const {
    execute: executeCheck,
    pending: isChecking,
    value: checkData,
    error: checkError
  } = useAsync(() => fetchUpdateSoftwareCheck(url, user), false);
  const {
    execute: executeDanuxCheck,
    pending: isCheckingDanux,
    value: checkDanuxData,
    error: checkDanuxError
  } = useAsync(() => fetchUpdateSoftwareDanuxCheck(url, user), false);
  const {
    isSucceeded: isUploadingSucceeded,
    isFailed: isUploadingFailed
  } = getStepData(isUploading, value, error);
  const {
    isSucceeded: isSecurityCheckSucceeded,
    isFailed: isSecurityCheckFailed
  } = getStepData(isSecurityChecking, securityCheckData, securityCheckError);
  const {
    isSucceeded: isUnzippingFileSucceeded,
    isFailed: isUnzippingFileFailed
  } = getStepData(isUnzippingFile, unzipFileData, unzipFileError);
  const {
    isSucceeded: isCheckingSucceeded,
    isFailed: isCheckingFailed
  } = getStepData(isChecking, checkData, checkError);
  const {
    isSucceeded: isCheckingDanuxSucceeded,
    isFailed: isCheckingDanuxFailed
  } = getStepData(isCheckingDanux, checkDanuxData, checkDanuxError);
  React.useEffect(() => {
    let timer = null;
    if (!isUploading && isUploadingSucceeded && !isUploadingFailed && !isSecurityChecking && !isSecurityCheckSucceeded && !isSecurityCheckFailed) {
      timer = setTimeout(() => {
        executeSecurityCheck();
      }, 500);
    }
    return () => clearTimeout(timer);
  }, [isUploading, isUploadingSucceeded, isUploadingFailed, isSecurityChecking, isSecurityCheckSucceeded, isSecurityCheckFailed]);
  React.useEffect(() => {
    let timer = null;
    if (!isSecurityChecking && isSecurityCheckSucceeded && !isSecurityCheckFailed && !isUnzippingFile && !isUnzippingFileSucceeded && !isUnzippingFileFailed) {
      timer = setTimeout(() => {
        executeUnzipFile();
      }, 500);
    }
    return () => clearTimeout(timer);
  }, [isSecurityChecking, isSecurityCheckSucceeded, isSecurityCheckFailed, isUnzippingFile, isUnzippingFileSucceeded, isUnzippingFileFailed]);
  React.useEffect(() => {
    let timer = null;
    if (!isUnzippingFile && isUnzippingFileSucceeded && !isUnzippingFileFailed && !isChecking && !isCheckingSucceeded && !isCheckingFailed) {
      if (checkPrerequisites) {
        timer = setTimeout(() => {
          executeCheck();
        }, 500);
      }
    }
    return () => clearTimeout(timer);
  }, [isUnzippingFile, isUnzippingFileSucceeded, isUnzippingFileFailed, isChecking, isCheckingSucceeded, isCheckingFailed]);
  React.useEffect(() => {
    let timer = null;
    if (!isUnzippingFile && isUnzippingFileSucceeded && !isUnzippingFileFailed && !isCheckingDanux && !isCheckingDanuxSucceeded && !isCheckingDanuxFailed) {
      if (checkLPKVersion) {
        timer = setTimeout(() => {
          executeDanuxCheck();
        }, 500);
      }
    }
    return () => clearTimeout(timer);
  }, [isUnzippingFile, isUnzippingFileSucceeded, isUnzippingFileFailed, isCheckingDanux, isCheckingDanuxSucceeded, isCheckingDanuxFailed]);
  const isProcessing = isUploading || isSecurityChecking || isUnzippingFile || isChecking || isCheckingDanux;
  const isCompleted = isUploadingSucceeded && isSecurityCheckSucceeded && isUnzippingFileSucceeded && (isCheckingSucceeded || !checkPrerequisites) && (isCheckingDanuxSucceeded || !checkLPKVersion);
  const isFailed = isUploadingFailed || isUnzippingFileFailed || isCheckingFailed || isSecurityCheckFailed || isCheckingDanuxFailed;
  const danuxUpdateNeeded = [checkError, checkDanuxError].some(e => (e === null || e === void 0 ? void 0 : e.message) === RETURN_CODE_DANUX_UPDATE_NEEDED);
  const downgradeNotAllowed = [checkError, checkDanuxError].some(e => (e === null || e === void 0 ? void 0 : e.message) === RETURN_CODE_NO_DOWNGRADE_ALLOWED);
  return {
    isProcessing,
    isFailed,
    isUploading,
    isUploadingSucceeded,
    isSecurityChecking,
    isSecurityCheckSucceeded,
    isUnzippingFile,
    isUnzippingFileSucceeded,
    isChecking,
    isCheckingDanux,
    fileUploadProgress: progress,
    isCheckingSucceeded,
    isCheckingDanuxSucceeded,
    isCompleted,
    isUploadingFailed,
    isSecurityCheckFailed,
    isUnzippingFileFailed,
    isCheckingFailed,
    isCheckingDanuxFailed,
    danuxUpdateNeeded,
    downgradeNotAllowed
  };
}

export { useSoftwareCheck };
