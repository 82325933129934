import {
  BuildXMLOptions,
  fetchXMLData,
  isCurrentlySelectedUnit,
  Unit,
  User,
  XML_ACTION,
  XML_DEVICE_NODETYPE,
  XML_TAG,
} from '@danfoss/etui-sm-xml';
import { getArray } from 'utils';
import { fetchGenericDevices } from '../components/DeviceParameterModal/actions';

export type Val = {
  cid: string;
  display: string;
  error: string;
  name: string;
  node: string;
  nodetype: string;
  parval: string;
  pending: string;
  stat: string;
  statcode: string;
  units: string;
  units_index: string;
  vid: string;
  mod: string;
  point: string;
  kw: string;
};

type DataSource = {
  id: string; // The sourceId
  value: number | string; // The raw value
  name?: string; // The name of the parameter
  _?: string; // The value as string including unit
};

export enum PARAMETER_ID_INDEXES {
  HOST = 0,
  NODETYPE = 1,
  NODE = 2,
  MOD = 5,
  POINT = 6,
  PARAM_ID = 7,
  CID = 10,
  VID = 11,
}

const shouldUpdateSession = (skipSessionUpdate?: boolean) =>
  skipSessionUpdate ? { session_update: 'no' } : {};

export function getSourceIdMeta(sourceId: string): {
  host: string;
  nodetype: string;
  node: string;
  mod: string;
  point: string;
  paramId: string;
  cid: string;
  vid: string;
} {
  if (!sourceId) {
    return null;
  }
  const [, idPart] = sourceId.split('_');
  const id = idPart ? idPart.split('-') : sourceId.split('-');

  const host = id[PARAMETER_ID_INDEXES.HOST];
  const nodetype = id[PARAMETER_ID_INDEXES.NODETYPE];
  const node = id[PARAMETER_ID_INDEXES.NODE];
  const mod = id[PARAMETER_ID_INDEXES.MOD];
  const point = id[PARAMETER_ID_INDEXES.POINT];
  const paramId = id[PARAMETER_ID_INDEXES.PARAM_ID];
  const cid = id[PARAMETER_ID_INDEXES.CID];
  const vid = id[PARAMETER_ID_INDEXES.VID];

  return {
    host,
    nodetype,
    node,
    mod,
    point,
    paramId,
    cid,
    vid,
  };
}

function getIsPoint(sourceId: string) {
  const id = sourceId.split('-');
  return id.length === 7;
}

function getIsParameter(sourceId: string) {
  const id = sourceId.split('-');
  return id.length === 12;
}

function getIsMeter(sourceId: string) {
  const id = sourceId.split('-');
  const { nodetype } = getSourceIdMeta(sourceId);
  return id.length === 8 && nodetype === XML_DEVICE_NODETYPE.NODETYPE_EM;
}

function getIsSensorPoint(sourceId: string) {
  const { nodetype } = getSourceIdMeta(sourceId);

  return nodetype === XML_DEVICE_NODETYPE.NODETYPE_SI;
}

function getIsInputPoint(sourceId: string) {
  const { nodetype } = getSourceIdMeta(sourceId);

  return nodetype === XML_DEVICE_NODETYPE.NODETYPE_OI;
}

function getIsVarOutPoint(sourceId: string) {
  const { nodetype } = getSourceIdMeta(sourceId);

  return nodetype === XML_DEVICE_NODETYPE.NODETYPE_VO;
}

function getIsRelayPoint(sourceId: string) {
  const { nodetype } = getSourceIdMeta(sourceId);

  return nodetype === XML_DEVICE_NODETYPE.NODETYPE_RO;
}

function mapSourceIdToValItem(sourceId: string): BuildXMLOptions {
  if (getIsParameter(sourceId)) {
    const { nodetype, node, cid, vid } = getSourceIdMeta(sourceId);

    return {
      tag: XML_TAG.VAL,
      attributes: { nodetype, node, cid, vid },
    };
  }

  return null;
}

function mapSourceIdToSensorItem(sourceId: string): BuildXMLOptions {
  if (getIsPoint(sourceId)) {
    const { node, mod, point } = getSourceIdMeta(sourceId);

    return {
      tag: XML_TAG.SENSOR,
      attributes: { node, mod, point },
    };
  }

  return null;
}

function mapSourceIdToInputItem(sourceId: string): BuildXMLOptions {
  if (getIsPoint(sourceId)) {
    const { node, mod, point } = getSourceIdMeta(sourceId);

    return {
      tag: XML_TAG.INPUT,
      attributes: { node, mod, point },
    };
  }

  return null;
}

function mapSourceIdToVarOutputItem(sourceId: string): BuildXMLOptions {
  if (getIsPoint(sourceId)) {
    const { node, mod, point } = getSourceIdMeta(sourceId);

    return {
      tag: XML_TAG.VAR_OUTPUT,
      attributes: { node, mod, point },
    };
  }

  return null;
}

function mapSourceIdToMeterItem(sourceId: string): BuildXMLOptions {
  if (getIsMeter(sourceId)) {
    const { paramId } = getSourceIdMeta(sourceId);

    return {
      tag: XML_TAG.METER,
      attributes: { id: paramId },
    };
  }

  return null;
}

function mapSourceIdToRelayItem(sourceId: string): BuildXMLOptions {
  if (getIsPoint(sourceId)) {
    const { node, mod, point } = getSourceIdMeta(sourceId);

    return {
      tag: XML_TAG.RELAY,
      attributes: { node, mod, point },
    };
  }

  return null;
}

export function getSourceIdByVal(dataSourceIds: string[], val: Val) {
  return val && dataSourceIds
    ? dataSourceIds.find(sourceId => {
        const { nodetype, node, cid, vid } = getSourceIdMeta(sourceId);
        if (
          nodetype === val.nodetype &&
          node === val.node &&
          cid === val.cid &&
          vid === val.vid
        ) {
          return sourceId;
        }
        return null;
      })
    : null;
}

export function getSourceIdByPointVal(
  sourceIds: string[],
  nodetype: string,
  val: Val,
) {
  return val && sourceIds
    ? sourceIds.find(sourceId => {
        const {
          nodetype: idNodetype,
          node,
          mod,
          point,
        } = getSourceIdMeta(sourceId);
        if (
          idNodetype === nodetype &&
          node === val.node &&
          mod === (val.mod ?? '0') &&
          point === (val.point ?? '0')
        ) {
          return sourceId;
        }
        return null;
      })
    : null;
}

async function fetchReadValDataSources(
  url: string,
  user: User,
  sourceIds: string[],
  skipSessionUpdate?: boolean,
) {
  const items = sourceIds
    .filter(getIsParameter)
    .map(mapSourceIdToValItem)
    .filter(Boolean);

  if (items.length === 0) {
    return [];
  }
  const genericDevices: {
    node: string;
    name: string;
  }[] = await fetchGenericDevices(url, user, skipSessionUpdate);

  const { val } = await fetchXMLData<{ val: Val | Val[] }>({
    url,
    attributes: {
      user: user.user,
      password: user.password,
      lang: user.language,
      action: XML_ACTION.READ_VAL,
      ...shouldUpdateSession(skipSessionUpdate),
    },
    items,
  });
  const values = getArray(val).filter(v => !v.error || v.error === '0');

  const response: DataSource[] = values.map(value => {
    const id = getSourceIdByVal(sourceIds, value);
    const { name: deviceName } = genericDevices.find(
      d => d.node === value.node,
    ) || { name: '' };
    return {
      id,
      deviceName,
      value: value.parval,
      ...value,
    };
  });

  return response;
}

async function fetchReadSensorDataSources(
  url: string,
  user: User,
  sourceIds: string[],
  skipSessionUpdate?: boolean,
) {
  const items = sourceIds
    .filter(getIsPoint)
    .filter(getIsSensorPoint)
    .map(mapSourceIdToSensorItem)
    .filter(Boolean);

  if (items.length === 0) {
    return [];
  }

  const { sensor } = await fetchXMLData<{ sensor: Val | Val[] }>({
    url,
    attributes: {
      user: user.user,
      password: user.password,
      lang: user.language,
      action: XML_ACTION.READ_SENSOR,
      ...shouldUpdateSession(skipSessionUpdate),
    },
    items,
  });

  const nodetype = XML_DEVICE_NODETYPE.NODETYPE_SI;
  const values = getArray(sensor);

  const response: DataSource[] = values.map(value => {
    const id = getSourceIdByPointVal(sourceIds, nodetype, value);
    return {
      id,
      deviceName: value.name,
      value: value.parval,
      nodetype,
      ...value,
    };
  });

  return response;
}

async function fetchReadInputDataSources(
  url: string,
  user: User,
  sourceIds: string[],
  skipSessionUpdate?: boolean,
) {
  const items = sourceIds
    .filter(getIsPoint)
    .filter(getIsInputPoint)
    .map(mapSourceIdToInputItem)
    .filter(Boolean);

  if (items.length === 0) {
    return [];
  }

  const { input } = await fetchXMLData<{ input: Val | Val[] }>({
    url,
    attributes: {
      user: user.user,
      password: user.password,
      lang: user.language,
      action: XML_ACTION.READ_INPUT,
      ...shouldUpdateSession(skipSessionUpdate),
    },
    items,
  });

  const values = getArray(input);

  const nodetype = XML_DEVICE_NODETYPE.NODETYPE_OI;
  const response: DataSource[] = values.map(value => {
    const id = getSourceIdByPointVal(sourceIds, nodetype, value);
    return {
      id,
      deviceName: value.name,
      value: value.parval,
      nodetype,
      ...value,
    };
  });

  return response;
}

async function fetchReadVarOutDataSources(
  url: string,
  user: User,
  sourceIds: string[],
  skipSessionUpdate?: boolean,
) {
  const items = sourceIds
    .filter(getIsPoint)
    .filter(getIsVarOutPoint)
    .map(mapSourceIdToVarOutputItem)
    .filter(Boolean);

  if (items.length === 0) {
    return [];
  }

  const { var_output } = await fetchXMLData<{ var_output: Val | Val[] }>({
    url,
    attributes: {
      user: user.user,
      password: user.password,
      lang: user.language,
      action: XML_ACTION.READ_VAR_OUT,
      ...shouldUpdateSession(skipSessionUpdate),
    },
    items,
  });

  const nodetype = XML_DEVICE_NODETYPE.NODETYPE_VO;
  const values = getArray(var_output);

  const response: DataSource[] = values.map(value => {
    const id = getSourceIdByPointVal(sourceIds, nodetype, value);
    return {
      id,
      deviceName: value.name,
      value: value.parval,
      nodetype,
      ...value,
    };
  });

  return response;
}

async function fetchReadMeterDataSources(
  url: string,
  user: User,
  sourceIds: string[],
  skipSessionUpdate?: boolean,
) {
  const items = sourceIds
    .filter(getIsMeter)
    .map(mapSourceIdToMeterItem)
    .filter(Boolean);

  if (items.length === 0) {
    return [];
  }

  const { meter } = await fetchXMLData<{ meter: Val | Val[] }>({
    url,
    attributes: {
      user: user.user,
      password: user.password,
      lang: user.language,
      action: XML_ACTION.READ_METER,
      ...shouldUpdateSession(skipSessionUpdate),
    },
    items,
  });

  const nodetype = XML_DEVICE_NODETYPE.NODETYPE_EM;
  const values = getArray(meter);

  const response: DataSource[] = values.map(value => {
    const id = getSourceIdByPointVal(sourceIds, nodetype, value);
    return {
      deviceName: value.name,
      value: value.kw,
      nodetype,
      _: `${value.kw} kw`,
      ...value,
      id,
    };
  });

  return response;
}

async function fetchReadRelayDataSources(
  url: string,
  user: User,
  sourceIds: string[],
  skipSessionUpdate?: boolean,
) {
  const items = sourceIds
    .filter(getIsPoint)
    .filter(getIsRelayPoint)
    .map(mapSourceIdToRelayItem)
    .filter(Boolean);

  if (items.length === 0) {
    return [];
  }

  const { relay } = await fetchXMLData<{ relay: Val | Val[] }>({
    url,
    attributes: {
      user: user.user,
      password: user.password,
      lang: user.language,
      action: XML_ACTION.READ_RELAY,
      ...shouldUpdateSession(skipSessionUpdate),
    },
    items,
  });

  const nodetype = XML_DEVICE_NODETYPE.NODETYPE_RO;
  const values = getArray(relay);

  const response: DataSource[] = values.map(value => {
    const id = getSourceIdByPointVal(sourceIds, nodetype, value);
    return {
      id,
      deviceName: value.name,
      value: value.parval,
      nodetype,
      ...value,
    };
  });

  return response;
}

export async function fetchDataSourceBySourceIds(
  units: Unit[],
  getFirstValidUrl,
  user: User,
  sourceIds: string[],
  skipSessionUpdate?: boolean,
): Promise<DataSource[]> {
  if (!sourceIds?.length) return [];

  const dataPromises = units.map(unit =>
    fetchDataSourceIdsByUnitNetwork(
      getFirstValidUrl(unit),
      unit.unit_addr,
      user,
      sourceIds,
      skipSessionUpdate && isCurrentlySelectedUnit(unit),
    ),
  );
  const data = await Promise.all(dataPromises);
  return data.flat();
}

async function fetchDataSourceIdsByUnitNetwork(
  url,
  unitHost,
  user,
  allSourceIds,
  skipSessionUpdate = false,
) {
  const sourceIds = allSourceIds.filter(id => {
    const { host } = getSourceIdMeta(id);
    return host === unitHost;
  });

  const inputDataSources = await fetchReadInputDataSources(
    url,
    user,
    sourceIds,
    skipSessionUpdate,
  );

  const sensorDataSources = await fetchReadSensorDataSources(
    url,
    user,
    sourceIds,
    skipSessionUpdate,
  );

  const varOutputDataSources = await fetchReadVarOutDataSources(
    url,
    user,
    sourceIds,
    skipSessionUpdate,
  );

  const meterDataSources = await fetchReadMeterDataSources(
    url,
    user,
    sourceIds,
    skipSessionUpdate,
  );

  const relayDataSources = await fetchReadRelayDataSources(
    url,
    user,
    sourceIds,
    skipSessionUpdate,
  );

  const deviceParameterDataSources = await fetchReadValDataSources(
    url,
    user,
    sourceIds,
    skipSessionUpdate,
  );

  return [
    ...inputDataSources,
    ...sensorDataSources,
    ...varOutputDataSources,
    ...relayDataSources,
    ...meterDataSources,
    ...deviceParameterDataSources,
  ];
}
