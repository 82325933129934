import React from 'react';
import {
  ConfigurationListItem,
  XML_DEVICE_NODETYPE,
  XML_REFRIG_COPY_TAB_INDEX,
  fetchLayoutListByUnit,
} from '@danfoss/etui-sm-xml';
import useSWR from 'swr';
import { useAuth } from '@danfoss/etui-sm/';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Notification } from '@danfoss/etui-core';
import { useRefrigCopy } from '../../context';
import {
  GraphTableContent,
  GraphTableData,
} from '../../types/RefrigCopy.types';
import { REFRIG_SUCCESS_RESPONSE } from '../../../RefrigLayoutModal/types';
import { writeConfigurationListItem } from '../../../../../Configuration';
import { withErrorHandled } from '../../../../../Configuration/utils/with-error-handled';
import { filterGraphTableContentList, processGraphTableData } from './actions';
import { GraphFlowPage } from './GraphFlowPage';

interface GraphFlowContentProps {
  tableAddress: string;
}

export const GraphFlowContent = ({ tableAddress }: GraphFlowContentProps) => {
  const { user } = useAuth();

  const { t } = useTranslation();
  const theme = useTheme();

  const { unit, currentUnitUrl: url } = useRefrigCopy();

  const {
    page,
    setMultipage,
    graphTableData,
    setGraphTableData,
    searchMode,
    isAllPageDataFetched,
    searchValue,
    allPageData,
    pageValues,
    setIsAllPageDataFetched,
    setAllPageData,
    setIsPageDataFetched,
  } = useRefrigCopy();

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const networkTableData = await fetchLayoutListByUnit([
          url,
          unit,
          tableAddress,
          XML_REFRIG_COPY_TAB_INDEX.GRAPH,
          user,
          page,
          XML_DEVICE_NODETYPE.NODETYPE_GN,
        ]);
        if (networkTableData?.items?.l?.length) {
          const graphTableContentList: GraphTableContent[] =
            processGraphTableData(networkTableData as any);
          setMultipage(networkTableData?.multipage);
          setGraphTableData({ graphTableContent: graphTableContentList });
          setIsPageDataFetched(true);
        }
      } catch (error) {
        Notification.error({
          message: t('t17'),
          description: t('t3403'),
          duration: 3,
          theme,
        });
      }
    };
    if (!graphTableData?.graphTableContent?.length) {
      setIsPageDataFetched(false);
      fetch();
    } else {
      fetch();
    }
  }, [page]);

  const handleOnSelect = async (listItem: ConfigurationListItem) => {
    const response = await withErrorHandled(
      t,
      theme,
      writeConfigurationListItem,
    )(
      url,
      user,
      null,
      `${tableAddress}-${XML_REFRIG_COPY_TAB_INDEX.GRAPH}`,
      null,
      '0',
      '0',
      listItem,
      listItem.fVal,
      listItem.iVal,
      '',
    );
    if (response?.operation === REFRIG_SUCCESS_RESPONSE) {
      revalidate();
    }
  };

  const revalidate = async () => {
    if (searchValue) {
      const result = await fetchAllPages(pages);
      let allContentList: GraphTableContent[] = [];
      allContentList = processGraphTableData(result);
      const filteredGraphTableContentList = filterGraphTableContentList(
        allContentList,
        searchValue,
      ) as GraphTableContent[];
      const filteredGraphTableData: GraphTableData = {
        graphTableContent: filteredGraphTableContentList,
      };
      setIsAllPageDataFetched(true);
      setAllPageData(allContentList);
      setGraphTableData(filteredGraphTableData);
    } else {
      const tableList = await fetchLayoutListByUnit([
        url,
        unit,
        tableAddress,
        XML_REFRIG_COPY_TAB_INDEX.GRAPH,
        user,
        page.toString(),
        XML_DEVICE_NODETYPE.NODETYPE_GN,
      ]);
      if (tableList?.items?.l) {
        const graphTableContentList: GraphTableContent[] =
          processGraphTableData(tableList as any);
        setMultipage(tableList?.multipage);
        setGraphTableData({ graphTableContent: graphTableContentList });
        setMultipage(tableList?.multipage);
        setGraphTableData({ graphTableContent: graphTableContentList });
      }
    }
  };

  const pages = searchMode ? pageValues : [];

  const fetchAllPages = async (pages: number[]) => {
    const response = [];
    await pages.reduce(async (promise, page) => {
      await promise;
      page -= 1;
      const data = await fetchLayoutListByUnit([
        url,
        unit,
        tableAddress,
        XML_REFRIG_COPY_TAB_INDEX.GRAPH,
        user,
        page.toString(),
        XML_DEVICE_NODETYPE.NODETYPE_GN,
      ]);
      response.push(data);
    }, Promise.resolve());
    const allPageResponse = response.filter(data => data !== undefined).flat();
    return allPageResponse;
  };

  const { data, isLoading: isAllPagesDataLoading } = useSWR(
    pages,
    pages?.length && !allPageData?.length ? fetchAllPages : null,
  );
  if (data?.length && !allPageData?.length && !isAllPagesDataLoading) {
    let allContentList: GraphTableContent[] = [];
    allContentList = processGraphTableData(data);
    const filteredGraphTableContentList = filterGraphTableContentList(
      allContentList,
      searchValue,
    ) as GraphTableContent[];
    const filteredGraphTableData: GraphTableData = {
      graphTableContent: filteredGraphTableContentList,
    };
    setIsAllPageDataFetched(true);
    setAllPageData(allContentList);
    setGraphTableData(filteredGraphTableData);
  }
  React.useEffect(() => {
    if (searchMode && isAllPageDataFetched) {
      const filteredGraphTableContentList = filterGraphTableContentList(
        allPageData,
        searchValue,
      ) as GraphTableContent[];
      const filteredGraphTableData: GraphTableData = {
        graphTableContent: filteredGraphTableContentList,
      };
      setGraphTableData(filteredGraphTableData);
    }
  }, [searchValue]);

  return (
    <>
      <GraphFlowPage handleOnSelect={handleOnSelect} />
    </>
  );
};
