import * as React from 'react';
import useSWR from 'swr';
import { useAuth, useXmlResource } from '@danfoss/etui-sm/';
import { isXmlError } from '@danfoss/etui-sm-xml';
import { Notification } from '@danfoss/etui-core';
import { useTheme } from '@danfoss/etui-system';
import { useTranslation } from 'react-i18next';
import { DiscoveryTableData } from '../../../../RefrigLayoutModal/types';
import { useRefrigLayout } from '../../../../RefrigLayoutModal/context';
import { fetchDeviceListData } from '../../../../RefrigLayoutModal/actions';
import { getTypeBasedOnDeviceType } from '../../../../RefrigLayoutModal/utils';
import { addHvacDevice, copyHvacDevice } from '../../actions';
import { HVACFinalPage } from './HVACFinalPage';

export function HVACFinalContent() {
  const { url: xmlBackendURL } = useXmlResource();
  const { user } = useAuth();
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    hvacDeviceTypeModelList,
    discoveryTableData,
    hvacFinalTableData,
    deviceConstraintsData,
    setHvacDeviceTypeModelList,
    setHvacFinalTableData,
    isOfflineProgramming,
    filteredTableData,
    unit,
  } = useRefrigLayout();

  const type: number = getTypeBasedOnDeviceType(3);
  const {
    data: hvacDeviceModelList,
    isLoading,
    error,
  } = useSWR(
    () => [xmlBackendURL, user, type, unit],
    !hvacDeviceTypeModelList ? fetchDeviceListData : null,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
  );
  if (isXmlError(error)) {
    Notification.error({
      message: t('t17'),
      description: t('t3364'),
      duration: 3,
      theme,
    });
  }
  const tableDataLoading = !error && isLoading;
  React.useEffect(() => {
    if (hvacDeviceModelList) {
      setHvacDeviceTypeModelList(hvacDeviceModelList);
      setHvacFinalTableData(
        isOfflineProgramming ? discoveryTableData : filteredTableData,
      );
    }
  }, [hvacDeviceModelList]);

  const handleOnCopyOk = (copyAmount: number, selectedRowIndex: number) => {
    let copiedHvacFinalTableData: DiscoveryTableData[] = copyHvacDevice(
      copyAmount,
      selectedRowIndex,
      hvacFinalTableData,
      deviceConstraintsData,
      t,
    );
    copiedHvacFinalTableData = [...copiedHvacFinalTableData];
    setHvacFinalTableData(copiedHvacFinalTableData);
  };

  const handleDeleteSelectedRow = (selectedRowIndex: number) => {
    hvacFinalTableData.splice(selectedRowIndex, 1);
    const deletedHvacFinalTableData: DiscoveryTableData[] = [
      ...hvacFinalTableData,
    ];
    setHvacFinalTableData(deletedHvacFinalTableData);
  };

  const handleOnAddRack = () => {
    const addedHvacFinalTableData: DiscoveryTableData[] = addHvacDevice(
      hvacFinalTableData,
      hvacDeviceTypeModelList,
      deviceConstraintsData,
      t,
    );
    setHvacFinalTableData([...addedHvacFinalTableData]);
  };

  return (
    <HVACFinalPage
      tableDataLoading={tableDataLoading}
      handleOnCopyOk={handleOnCopyOk}
      handleDeleteSelectedRow={handleDeleteSelectedRow}
      handleOnAddRack={handleOnAddRack}
    />
  );
}
