import { useXmlResource } from '@danfoss/etui-sm';
import { getUnitMetadata, trimUrlEnding } from '@danfoss/etui-sm-xml';

export function getSystemMangerFirmwareVersion(): string {
  let installationVersion = '';
  const { url } = useXmlResource();
  const trimmedUrl = trimUrlEnding(url);
  const metadata = getUnitMetadata(trimmedUrl) || {};
  const oldInstallationVersionPropName = 'Installation-version';
  const newInstallationVersionPropName = 'Installation_version';
  installationVersion =
    metadata[newInstallationVersionPropName] ||
    metadata[oldInstallationVersionPropName];
  return installationVersion;
}
