import {
  fetchXMLData,
  User,
  XML_ACTION,
  MODEL_IDX,
  XML_DEVICE_LIST,
  Unit,
  getUnitUrl,
} from '@danfoss/etui-sm-xml';
import { TFunction } from 'i18next';
import {
  DeviceConstraintsData,
  DEVICE_STATUS,
  DEVICE_TYPE,
  DiscoveryDeviceDetail,
  DiscoveryDeviceStatusCnt,
  DiscoveryPageData,
  DiscoveryResponse,
  DiscoveryTableData,
  RescanResponse,
  ScanStatusResponse,
} from '../types/DiscoveryPage.types';
import { getBusDescription } from '../utils';
import { getTypeDescription } from '../utils/get-type-description';

async function startRescan([url, user, unit]: [string, User, Unit]) {
  try {
    const data: RescanResponse = await fetchXMLData({
      url: getUnitUrl(url, unit),
      attributes: {
        user: user?.user,
        password: user?.password,
        lang: user?.language,
        storeview_only: '1',
        version: 'C',
        action: XML_ACTION.NETWORK_RESCAN,
      },
    });
    return data;
  } catch (e) {
    throw new Error(e);
  }
}

async function getScanStatus([url, user, unit]: [string, User, Unit]) {
  try {
    const data: ScanStatusResponse = await fetchXMLData({
      url: getUnitUrl(url, unit),
      attributes: {
        user: user?.user,
        password: user?.password,
        lang: user?.language,
        storeview_only: '1',
        version: 'C',
        action: XML_ACTION.NETWORK_SCAN_STATUS,
      },
    });
    return data;
  } catch (e) {
    throw new Error(e);
  }
}

async function fetchDiscoveryDataByUnit([
  url,
  user,
  t,
  isOfflineProgramming,
  unit,
]: [string, User, TFunction, boolean, Unit]) {
  try {
    const data: DiscoveryResponse = await fetchXMLData({
      url: getUnitUrl(url, unit),
      attributes: {
        user: user?.user,
        password: user?.password,
        lang: user?.language,
        storeview_only: '1',
        version: 'C',
        action: XML_ACTION.READ_REFRIG_SCAN,
      },
    });
    return data
      ? processDiscoveryResponse(data, t, isOfflineProgramming)
      : null;
  } catch (e) {
    throw new Error(e);
  }
}

const getCaseTypes = (data: DiscoveryResponse) =>
  data.casetypes.type.map(casetype => casetype.name);

const processDiscoveryResponse = (
  data: DiscoveryResponse,
  t: TFunction,
  isOfflineProgramming: boolean,
): DiscoveryPageData => {
  const filteredData: DiscoveryDeviceDetail[] = filterDiscoveryResponse(
    data,
    isOfflineProgramming,
  );
  const caseTypes: string[] = getCaseTypes(data);
  let discoveryTableData: DiscoveryTableData[] = getDiscoveryTableData(
    filteredData,
    t,
  );
  const deviceConstraintsData: DeviceConstraintsData =
    getDeviceConstraintsData(data);
  const discoveryDeviceStatusCnt: DiscoveryDeviceStatusCnt =
    getDiscoveryDeviceStatusCnt(discoveryTableData);
  const arrangedDevices: DiscoveryTableData[] =
    arrangeDevice(discoveryTableData);
  discoveryTableData = [...arrangedDevices];
  const discoveryPageData: DiscoveryPageData = {
    discoveryTableData,
    discoveryDeviceStatusCnt,
    deviceConstraintsData,
    caseTypes,
  };
  return discoveryPageData;
};

const arrangeDevice = (
  discoveryTableData: DiscoveryTableData[],
): DiscoveryTableData[] => {
  const devs = [];
  let rk = 0;
  let sg = 0;
  discoveryTableData?.forEach(device => {
    if (rk < device.rk && rk < 255) {
      rk = device.rk;
    }
    if (sg < device.sg && sg < 255) {
      sg = device.sg;
    }
    if (device.rk !== 0) {
      device.arranged = true;
      devs.push(device);
    }
  });
  discoveryTableData?.forEach(device => {
    if (
      !device.arranged &&
      (device.deviceType === DEVICE_TYPE.GEN_PACK ||
        device.deviceType === DEVICE_TYPE.GEN_DRIVE)
    ) {
      device.rk = ++rk;
      device.sg = ++sg;
      device.arranged = true;
      devs.push(device);
    }
  });
  discoveryTableData?.forEach(device => {
    if (!device.arranged && device.deviceType === DEVICE_TYPE.GEN_CIRCUIT) {
      device.arranged = true;
      devs.push(device);
    }
  });
  discoveryTableData?.forEach(device => {
    if (
      !device.arranged &&
      (device.deviceType === DEVICE_TYPE.GEN_PACK ||
        device.deviceType === DEVICE_TYPE.GEN_DRIVE)
    ) {
      device.rk = ++rk;
      device.sg = ++sg;
      device.arranged = true;
      devs.push(device);
    }
  });
  discoveryTableData?.forEach(device => {
    if (!device.arranged && device.deviceType === DEVICE_TYPE.GEN_CIRCUIT) {
      device.arranged = true;
      devs.push(device);
    }
  });
  discoveryTableData?.forEach(device => {
    if (
      (!device.arranged && device.deviceType === DEVICE_TYPE.UNKNOWN) ||
      device.deviceType === DEVICE_TYPE.PI200
    ) {
      device.arranged = true;
      devs.push(device);
    }
  });
  return devs;
};

const filterDiscoveryResponse = (
  data: DiscoveryResponse,
  isOfflineProgramming: boolean,
): DiscoveryDeviceDetail[] => {
  const filteredData: DiscoveryDeviceDetail[] = [];
  data?.devices?.d?.forEach(device1 => {
    let isDeviceFiltered = false;
    if (
      (device1.type === DEVICE_TYPE.RACK ||
        device1.type === DEVICE_TYPE.RACKSUCT ||
        device1.type === DEVICE_TYPE.NO_COMP ||
        device1.type === DEVICE_TYPE.CIRCUIT ||
        device1.type === DEVICE_TYPE.GEN_CIRCUIT) &&
      (isOfflineProgramming || device1.onln === '1')
    ) {
      filteredData.push(device1);
      isDeviceFiltered = true;
    }
    if (!isDeviceFiltered) {
      let same = false;
      for (const device2 of filteredData) {
        if (isSameDevice(device1, device2)) {
          same = true;
          break;
        }
      }
      let existingDb = false;
      if (!isOfflineProgramming && device1?.onln === '0') {
        existingDb = true;
      }
      if (!same && !existingDb) {
        filteredData.push(device1);
      }
    }
  });
  return filteredData;
};

const isSameDevice = (
  device1: DiscoveryDeviceDetail,
  device2: DiscoveryDeviceDetail,
) => {
  let isSame = false;
  if (
    ((device1?.type === DEVICE_TYPE.GEN_SUCT &&
      device2?.type === DEVICE_TYPE.GEN_SUCT) ||
      (device1?.type === DEVICE_TYPE.NO_COMP_SUCT &&
        device2?.type === DEVICE_TYPE.NO_COMP_SUCT)) &&
    device1?.addr === device2?.addr
  ) {
    return isSame;
  }
  isSame =
    Number(device1?.addr) &&
    device1?.type === device2?.type &&
    device1?.addr === device2?.addr;
  return isSame;
};

const getDeviceConstraintsData = (data: DiscoveryResponse) => {
  const deviceContraintsData: DeviceConstraintsData = {
    config_cnt: data?.devices?.config_cnt,
    gen_ofs_cs: data?.devices?.gen_ofs_cs,
    gen_ofs_ct: data?.devices?.gen_ofs_ct,
    gen_ofs_pk: data?.devices?.gen_ofs_pk,
    max_ca: data?.devices?.max_ca,
    max_cs: data?.devices?.max_cs,
    max_ct: data?.devices?.max_ct,
    max_naddr: data?.devices?.max_naddr,
    max_name: data?.devices?.max_name,
    max_octl: data?.devices?.max_octl,
    max_rk: data?.devices?.max_rk,
    max_sg: data?.devices?.max_sg,
    max_sg_gen: data?.devices?.max_sg_gen,
    scan_cnt: data?.devices?.scan_cnt,
  };
  return deviceContraintsData;
};

const getDiscoveryTableData = (
  devices: DiscoveryDeviceDetail[],
  t: TFunction,
): DiscoveryTableData[] => {
  const discoveryTableData: DiscoveryTableData[] = [];
  devices?.forEach((currentDevice, index) => {
    const discoveryTableDataObj: DiscoveryTableData = getDiscoveryTableDataObj(
      currentDevice,
      devices,
      index,
      t,
    );
    discoveryTableData.push(discoveryTableDataObj);
  });
  return discoveryTableData;
};

const getDiscoveryDeviceStatusCnt = (
  discoveryTableData: DiscoveryTableData[],
): DiscoveryDeviceStatusCnt => {
  let statusOrangeCnt: number = 0;
  let statusGreenCnt: number = 0;
  let statusRedCnt: number = 0;
  discoveryTableData?.forEach(tableData => {
    if (
      tableData?.deviceType === DEVICE_TYPE.GEN_SUCT ||
      tableData?.deviceType === DEVICE_TYPE.NO_COMP_SUCT
    ) {
      return;
    }
    switch (tableData?.status) {
      case DEVICE_STATUS.ORANGE:
        statusOrangeCnt = ++statusOrangeCnt;
        break;
      case DEVICE_STATUS.GREEN:
        statusGreenCnt = ++statusGreenCnt;
        break;
      case DEVICE_STATUS.RED:
        statusRedCnt = ++statusRedCnt;
        break;
      default:
        break;
    }
  });
  return {
    statusOrangeCnt,
    statusGreenCnt,
    statusRedCnt,
  };
};

const getModelIndex = (device: DiscoveryDeviceDetail) => {
  return Number(device.is_monitor)
    ? MODEL_IDX.IDX_MONITORING + 1
    : Number(device?.subcool)
    ? MODEL_IDX.IDX_SUBCOOL + 1
    : MODEL_IDX.IDX_IOCIRCUIT + 1;
};

const getDeviceData = (
  device: DiscoveryDeviceDetail,
  devices: DiscoveryDeviceDetail[],
  index: number,
  t: TFunction,
) => {
  const discoveryTableData: DiscoveryTableData = {
    name: getDeviceName(device),
    status: getDeviceStatus(device, devices),
    address: getDeviceAddress(device?.addr, device?.type),
    model: getDeviceModel(device, t),
    deviceType: device?.type,
    deviceId: index + 1,
    type: getTypeDescription(device?.type, t),
    code: device?.dev || '-',
    version: device?.ver || '-',
    deviceBus: getBusDescription(+device?.bus, device?.ip),
    cat1: getValue(device, 'cat1'),
    cat2: getValue(device, 'cat2'),
    cfg: getValue(device, 'cfg'),
    case_type: getValue(device, 'case_type'),
    dim1: getValue(device, 'dim1'),
    dim2: getValue(device, 'dim2'),
    dim3: getValue(device, 'dim3'),
    subcool: getValue(device, 'subcool'),
    sc: getValue(device, 'sc'),
    is_monitor: getValue(device, 'is_monitor'),
    circ_type: getValue(device, 'circ_type'),
    model_idx: device?.type === DEVICE_TYPE.CIRCUIT ? getModelIndex(device) : 0,
    mx_naddr: getValue(device, 'mx_naddr'),
    removed: false,
    newDevice: false,
    category: Number(getValue(device, 'cat1')),
    multi: Number(getValue(device, 'cat2')) & XML_DEVICE_LIST.DEV_MULTI_SECT,
    file: getValue(device, 'fil'),
    device: getValue(device, 'dev'),
    ip: getValue(device, 'ip'),
    online: getValue(device, 'onln'),
    rk: getDeviceRk(device),
    sg: getDeviceSg(device),
    assigned: false,
    num_sg: getDeviceNumSg(device),
    max_sg: getDeviceMaxSg(device),
    new_rk: 0,
  };

  return discoveryTableData;
};

const getDeviceAddress = (address: string, type: string) => {
  switch (type) {
    case DEVICE_TYPE.RACK:
    case DEVICE_TYPE.RACKSUCT:
    case DEVICE_TYPE.NO_COMP:
    case DEVICE_TYPE.CIRCUIT:
      return '-';
    default:
      return address || '-';
  }
};

const getValue = (device: DiscoveryDeviceDetail, key: string) =>
  device[key] || null;

const getDiscoveryTableDataObj = (
  device: DiscoveryDeviceDetail,
  devices: DiscoveryDeviceDetail[],
  index: number,
  t: TFunction,
) => {
  const discoveryTableData = getDeviceData(device, devices, index, t);

  return discoveryTableData;
};

const getDeviceRk = (device: DiscoveryDeviceDetail): number => {
  return +device?.rk || 0;
};
const getDeviceSg = (device: DiscoveryDeviceDetail): number => {
  return +device?.sg || 0;
};

const getDeviceNumSg = (device: DiscoveryDeviceDetail): number => {
  return device?.type === DEVICE_TYPE.CIRCUIT ? 0 : +device?.num_sg || 0;
};

const getDeviceMaxSg = (device: DiscoveryDeviceDetail): number => {
  return device?.type === DEVICE_TYPE.RACKSUCT ||
    device?.type === DEVICE_TYPE.CIRCUIT
    ? 0
    : +device?.max_sg || 0;
};

const getDeviceModel = (device: DiscoveryDeviceDetail, t: TFunction) => {
  switch (device?.type) {
    case DEVICE_TYPE.RACK:
      return t('t511');
    case DEVICE_TYPE.CIRCUIT:
      if (+device?.is_monitor) {
        return t('t127');
      }
      if (+device?.subcool) {
        return t('t570');
      }
      return t('t511');
    default:
      return device?.mdl || '-';
  }
};

const getDeviceName = (device: DiscoveryDeviceDetail) => {
  return device?.name === 'Uncfg'
    ? `${device?.mdl} ${device?.addr}`
    : device?.name || '-';
};

const getDeviceStatus = (
  currentDevice: DiscoveryDeviceDetail,
  devices: DiscoveryDeviceDetail[],
) => {
  let status = '';
  if (currentDevice?.type === DEVICE_TYPE.CIRCUIT) {
    status = DEVICE_STATUS.RED;
  }
  if (
    !status &&
    currentDevice?.type !== DEVICE_TYPE.GEN_SUCT &&
    currentDevice?.type !== DEVICE_TYPE.NO_COMP_SUCT &&
    currentDevice?.addr
  ) {
    devices.forEach(device => {
      if (
        currentDevice !== device &&
        currentDevice?.addr === device?.addr &&
        currentDevice?.rk !== device?.rk
      ) {
        status = DEVICE_STATUS.ORANGE;
      }
    });
  }
  if (!status) {
    if (currentDevice?.onln === '1') {
      status = DEVICE_STATUS.GREEN;
    } else {
      status = DEVICE_STATUS.RED;
    }
  }
  return status;
};

export { fetchDiscoveryDataByUnit, startRescan, getScanStatus };
