import * as React from 'react';
import { Button, Notification } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import {
  XML_HVAC_LAYOUT_TAB_INDEX,
  XML_REFRIG_LAYOUT_TAB_INDEX,
} from '@danfoss/etui-sm-xml';
import { Div } from '@danfoss/etui-system-elements';
import { useTheme } from '@danfoss/etui-system';
import { useModal } from '@danfoss/etui-sm';
import { useRefrigLayout } from '../../RefrigLayoutModal/context';
import { WizardConfirmationModal } from '../../WizardConfirmationModal';
import { HVACLayoutFinishModal } from './components/HVACLayoutFinishModal';

interface HVACLayoutModalActionsProps {
  onCancel: () => void;
  handleOnFinish: () => void;
}
export function HVACLayoutModalActions({
  onCancel,
  handleOnFinish,
}: HVACLayoutModalActionsProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    step,
    setStep,
    activeStepNumber,
    setActiveStepNumber,
    refrigSteps,
    setDiscoveryTableData,
    hvacFinalTableData,
    hvacDeviceTypeModelList,
    setFilteredTableData,
    setIsOfflineProgramming,
    setHvacFinalTableData,
    unit,
  } = useRefrigLayout();

  const handleOnOkClick = () => {
    setDefault();
    setStepValues();
  };

  const setDefault = () => {
    setDiscoveryTableData([]);
    setFilteredTableData([]);
    setIsOfflineProgramming(true);
    setHvacFinalTableData([]);
  };

  const [showWizardConfirmationModal] = useModal(WizardConfirmationModal, {
    handleOnOkClick,
    title: t('t596'),
    text: t('t3379'),
    subText: t('t3380'),
  });

  const [showHvacLayoutFinishModal] = useModal(HVACLayoutFinishModal, {
    hvacFinalTableData,
    hvacDeviceTypeModelList,
    handleOnFinish,
    unit,
  });

  const handleOnBack = () => {
    if (step === +XML_HVAC_LAYOUT_TAB_INDEX.HVAC) {
      showWizardConfirmationModal();
      return;
    }
    if (step === +XML_HVAC_LAYOUT_TAB_INDEX.DISCOVERY) {
      setDefault();
    }
    setStepValues();
  };

  const setStepValues = () => {
    let currentStep = step;
    let currentStepNumber = activeStepNumber;
    currentStep -= 1;
    currentStepNumber -= 1;

    if (currentStep < +XML_REFRIG_LAYOUT_TAB_INDEX.NETWORK) {
      onCancel();
    }
    if (
      currentStep < +XML_HVAC_LAYOUT_TAB_INDEX.HVAC &&
      currentStep >= +XML_HVAC_LAYOUT_TAB_INDEX.NETWORK
    ) {
      setStep(currentStep);
    }
    if (currentStepNumber >= refrigSteps[0]?.stepNumber) {
      setActiveStepNumber(currentStepNumber);
    }
  };

  const handleOnNextButton = () => {
    let currentStep = step;
    let currentStepNumber = activeStepNumber;
    currentStep += 1;
    currentStepNumber += 1;
    if (step === +XML_HVAC_LAYOUT_TAB_INDEX.HVAC) {
      if (!hvacFinalTableData?.length) {
        Notification.error({
          message: t('t17'),
          description: t('t3381'),
          duration: 3,
          theme,
        });
        return;
      }
      showHvacLayoutFinishModal();
    }
    if (
      currentStep <= +XML_HVAC_LAYOUT_TAB_INDEX.HVAC &&
      currentStep >= +XML_HVAC_LAYOUT_TAB_INDEX.NETWORK
    ) {
      setStep(currentStep);
    }
    if (currentStepNumber <= refrigSteps[refrigSteps.length - 1]?.stepNumber) {
      setActiveStepNumber(currentStepNumber);
    }
  };
  return (
    <Div testId="back-button" float="right">
      <Button
        width="150px"
        onClick={handleOnBack}
        variant="secondary"
        styles={{ root: { mr: 10 } }}
      >
        {step === +XML_HVAC_LAYOUT_TAB_INDEX.NETWORK ? t('t45') : t('t710')}
      </Button>
      <Button
        width="150px"
        variant="primary"
        styles={{ root: { mr: 10 } }}
        onClick={handleOnNextButton}
      >
        {step === +XML_HVAC_LAYOUT_TAB_INDEX.HVAC ? t('t430') : t('t118')}
      </Button>
    </Div>
  );
}
