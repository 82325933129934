import { getUnitUrlByUnitNetwork, fetchXMLData, XML_ACTION } from '@danfoss/etui-sm-xml';
import { _ as _objectSpread2 } from '../_rollupPluginBabelHelpers-4e4a2de3.js';
import '@danfoss/etui-core';
import '../constants/software-versions.js';
import '../utils/get-software-version-prefix.js';
import '../utils/get-is-matched-software-version.js';
import { authorizeUnits } from './authorize-units.js';
import { fetchMetadataFiles } from './fetch-metadata-files.js';
import '../utils/get-is-misc-device.js';

async function fetchOtherUnits(_ref) {
  let {
    dispatch,
    validUnitNetworks,
    initialUnit,
    user,
    protocol,
    showNotification
  } = _ref;
  const validNetworks = validUnitNetworks.filter(unitNetwork => unitNetwork.addr !== initialUnit.unit_addr);
  const urls = validNetworks.map(network => getUnitUrlByUnitNetwork(network, protocol));
  await fetchMetadataFiles(urls);
  await authorizeUnits(dispatch, urls, validUnitNetworks, initialUnit, user, showNotification);
  const readUnitsResponses = await Promise.all(validNetworks.map((n, i) => testUnitNetwork(user, protocol, n, i)));
  const {
    subUnits,
    failedNetworks
  } = readUnitsResponses.reduce((acc, _ref2) => {
    let {
      status,
      value
    } = _ref2;
    const arr = status === 'fulfilled' ? acc.subUnits : acc.failedNetworks;
    arr.push(value);
    return acc;
  }, {
    subUnits: [],
    failedNetworks: []
  });
  return {
    subUnits,
    failedNetworks
  };
}
async function testUnitNetwork(user, protocol, unitNetwork, index) {
  try {
    const url = getUnitUrlByUnitNetwork(unitNetwork, protocol);
    // This will reach the any sub unit on what ever url is set in the master unit.
    // If this call succeeds, then it will return a URL which it says it's reachable from
    // DO NOT TRUST THIS SUCKER !
    // The returned url might be a wrong url
    // but we can assume whatever url we used to reach it from the first time works.
    const value = await fetchXMLData({
      url,
      attributes: {
        user: user.user,
        password: user.password,
        lang: user.language,
        action: XML_ACTION.READ_UNITS,
        storeview_only: '1',
        version: 'C'
      }
    });
    const returnedUnitNetwork = Array.isArray(value.unit_internet) ? value.unit_internet.find(n => n.addr === unitNetwork.addr) : value.unit_internet;
    const proposedUnitUrl = getUnitUrlByUnitNetwork(returnedUnitNetwork, protocol);
    try {
      // Try and reach the unit at the url it's returned to us from its own settings
      await fetchXMLData({
        url: proposedUnitUrl,
        attributes: {
          user: user.user,
          password: user.password,
          lang: user.language,
          action: XML_ACTION.READ_GENERIC
        }
      });
    } catch (error) {
      // If it's unreachable then set the URL to the original reachable URL we just used in the fetchXMLData call
      // Then it's a bad unit, and it should be punished.
      // Now this means there is a settings error on the users side, we should consider a way to show that
      const originalIp = unitNetwork.ip;
      const unitInternet = value.unit_internet;
      const fixedUnitInternet = Array.isArray(unitInternet) ? unitInternet.map(n => _objectSpread2(_objectSpread2({}, n), {}, {
        ip: originalIp
      })) : [_objectSpread2(_objectSpread2({}, unitInternet), {}, {
        ip: originalIp
      })];
      value.unit_internet = fixedUnitInternet;
    }
    return {
      status: 'fulfilled',
      value
    };
  } catch (error) {
    // If the unit is unreachable on the first URL we have in the master
    // We reject it.
    return {
      status: 'rejected',
      value: {
        network: unitNetwork,
        index,
        error
      }
    };
  }
}

export { fetchOtherUnits };
