import { fetchXMLData } from '@danfoss/etui-sm-xml';
import '../../../_rollupPluginBabelHelpers-4e4a2de3.js';
import { useState, useEffect } from 'react';
import { XML_ACTION, XML_UPDATE_SW_CMD } from '@danfoss/etui-sm-xml/constants';
import { fetchUploadEDFFile } from './xmlLoadFileRequest.js';

const MAX_FILE_CHUNK_SIZE = 102400;
async function checkEDFExist(user, url, filename) {
  await fetchXMLData({
    url,
    attributes: {
      user: user.user,
      password: user.password,
      action: XML_ACTION.G3P_FILE_EXIST,
      filename
    }
  });
}
function useUploadEDF(user, url, filename, file, startUpload) {
  const [progress, setProgress] = useState(0);
  const [pending, setPending] = useState(false);
  const [responseXML, setResponseXML] = useState(null);
  const [error, setError] = useState(null);
  const handleSetProgress = (index, fileChunks) => {
    const totalChunks = fileChunks.length;
    setProgress(Math.min(Math.ceil(index * 100 / totalChunks), 100));
  };
  useEffect(() => {
    if (!startUpload) {
      return;
    }
    const uploadFile = async () => {
      setPending(true);
      const done = 0;
      const index = 1;
      let b64 = Buffer.from(file).toString('base64');
      b64 = b64.split('base64,').pop();
      const fileChunksArray = [];
      const fileSize = file.length;
      let b64FileSliced = b64;
      const fileLength = b64FileSliced.length;

      // If file length is greater than 102400 divide it into pieces and store it into an array.
      if (fileLength > MAX_FILE_CHUNK_SIZE) {
        // MAX file size allowed is 128MB, RMT used 102400.
        const numFileChunks = Math.ceil(fileLength / MAX_FILE_CHUNK_SIZE);
        let fileChunk;
        for (let i = 0; i < numFileChunks; i++) {
          // Last file chunk will be the remaining bites.
          fileChunk = b64FileSliced.substr(0, MAX_FILE_CHUNK_SIZE);
          fileChunksArray.push(fileChunk);
          b64FileSliced = b64FileSliced.slice(MAX_FILE_CHUNK_SIZE);
        }
      } else {
        fileChunksArray.push(b64FileSliced);
      }
      try {
        const response = await fetchUploadEDFFile(url, user, fileChunksArray, filename, fileSize, done, index, null, handleSetProgress);
        setPending(false);
        setResponseXML(response);
      } catch (e) {
        setPending(false);
        setError(e);
      }
    };
    uploadFile();
  }, [startUpload]);
  return {
    progress,
    pending,
    responseXML,
    error
  };
}
async function resetApp(user, url) {
  try {
    // This will always throw error.
    // Due to that the system manager will restart before returning the response.
    const response = await fetchXMLData({
      url,
      attributes: {
        user: user.user,
        password: user.password,
        action: XML_ACTION.UPDATE_SW,
        sw_upgrade_cmd: XML_UPDATE_SW_CMD.RESTART_APP
      }
    });
    if (response.return_code !== '0') {
      throw new Error();
    }
    return response;
  } catch (e) {
    return null;
  }
}

export { checkEDFExist, resetApp, useUploadEDF };
