import * as React from 'react';
import {
  Alert,
  Button,
  Form,
  Modal,
  PasswordInput,
  SelectInputCreatable,
  SelectInputOption,
  Spinner,
  TextInput,
  Toggle,
} from '@danfoss/etui-core';
import {
  changePassword,
  LoadStates,
  Protocol,
  useApp,
  useAuth,
  useUnit,
  useXmlResource,
} from '@danfoss/etui-sm/context';
import { CertificateError, CORSError, checkNetwork } from '@danfoss/etui-sm';
import { A, Div, P, Span } from '@danfoss/etui-system-elements';
import { Controller, useForm } from 'react-hook-form';
import { useLocalStorageSet, useQuery } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import { useHistory } from 'react-router-dom';
import { AUTH_HINTS } from 'pages/LoginPage/utils';
import { FormPasswords, LoginFormData } from 'pages/LoginPage/LoginPage.types';
import { CONFIRM_PASSWORD_ERRORS } from 'pages/LoginPage/utils/errors';
import {
  DESKTOP_INSTALLER_URL,
  EULA_URL,
  isElectron,
  isEmbeddedApp,
  targetAccessLevel,
} from '../../../../config';
import { useLoginCookies, useLoginParams } from '../../hooks';
import * as S from '../../styles';
import { SecurityWarning } from './SecurityWarning';

export const LoginForm = ({ onSetIpError, prefilledData, login }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [state, dispatch] = useApp();
  const { loadState, loadError } = useUnit();
  const { ip: xmlBackendIp, setIp, url } = useXmlResource();
  const { push } = useHistory();
  const query = useQuery();
  const {
    add: saveXmlBackendIpToLocalStorage,
    all: localStorageXmlBackendIps,
  } = useLocalStorageSet<string>('xmlBackendIps');
  const [isFormBeingSent, setIsFormBeingSent] = React.useState(false);
  const {
    dispatch: authDispatch,
    loginError,
    strongPassword,
    changePasswordResponse,
  } = useAuth();
  const { paramUser, paramIp, paramPassword, paramProxyConfig } =
    useLoginParams();

  const createIpOption = (value: string) => {
    const trimmedValue = value.trim();

    return {
      label: trimmedValue,
      value: trimmedValue,
    };
  };

  const handleIpOnCreate = (value: string = '') => {
    if (!value.trim()) return;

    const option = createIpOption(value);
    refreshErrors();
    saveXmlBackendIpToLocalStorage(option.label);
    setValue('ip', option);
    setIp(option.label);
  };

  const { register, control, errors, setValue, handleSubmit, getValues } =
    useForm<LoginFormData>();

  const [loadedProtocol, setLoadedProtocol] = React.useState<string>(null);
  const isHttps = state.protocol === Protocol.https;
  const isBusy =
    isFormBeingSent &&
    loadState === LoadStates.PENDING &&
    !loginError &&
    !loadError;

  const [authErrorHint, setAuthErrorHint] = React.useState<AUTH_HINTS>(null);
  const setProtocolToCookies = useLoginCookies(() =>
    setAuthErrorHint(AUTH_HINTS.SECURITY),
  );
  const errorHints = (errorLink = null, unitIp = null) => ({
    [AUTH_HINTS.CORS]: <CORSError link={errorLink} />,
    [AUTH_HINTS.CERTIFICATE]: <CertificateError unitIp={unitIp} />,
    [AUTH_HINTS.SECURITY]: <SecurityWarning />,
  });

  const hasLoginError = !!loginError;
  const hasIpError = !!errors.ip;

  const [isPasswordChanged, setIsPasswordChanged] = React.useState(false);
  const [isPasswordError, setIsPasswordError] = React.useState(false);
  const [isStrongPassword, setIsStrongPassword] = React.useState(null);
  const [passwordError, setPasswordError] = React.useState(null);

  const [isPasswordResetModalOpen, setIsPasswordResetModalOpen] =
    React.useState(false);

  const loginButtonRef = React.useRef<HTMLButtonElement>();

  const { ip, user } = getValues();

  const handleOnSubmit = async (data: LoginFormData) => {
    setIsFormBeingSent(true);
    setIsPasswordChanged(false);
    setIsPasswordError(false);
    if ('confirmPassword' in data) {
      createNewPassword(data);
    } else {
      login(data);
    }
  };

  const createNewPassword = async ({
    user,
    newPassword,
    password,
    confirmPassword,
  }: LoginFormData) => {
    if (checkIfPasswordValid({ password, newPassword, confirmPassword })) {
      await changePassword(authDispatch, user, newPassword, password, url);
    }
  };

  const checkIfPasswordValid = React.useCallback(
    (formPasswords: FormPasswords): boolean => {
      const error = CONFIRM_PASSWORD_ERRORS.find(error =>
        error.validate(formPasswords, isStrongPassword),
      );

      const hasError = !!error;
      setIsPasswordError(hasError);
      setPasswordError(hasError ? t(error.message) : null);
      return !hasError;
    },
    [],
  );

  const handleProtocolChange = React.useCallback(() => {
    if (ip) {
      const { value: selectedIp } = ip;
      query.set('ip', selectedIp || '');
    }

    if (user) {
      query.set('user', user || '');
    }

    push({ search: query.toString() });
    const newProtocol =
      state.protocol === Protocol.http ? Protocol.https : Protocol.http;
    dispatch({
      type: 'SET_PROTOCOL',
      payload: newProtocol,
    });

    if (!isElectron) {
      setProtocolToCookies(newProtocol);
      window.location.protocol = newProtocol;
    }
  }, [ip, user, state]);

  const onPasswordResetModalClose = () => {
    setIsPasswordResetModalOpen(false);
    setIsPasswordError(false);
    setPasswordError(null);
  };

  const refreshErrors = () => {
    loginError && authDispatch({ type: 'CLEAR_LOGIN_ERROR' });
    setAuthErrorHint(null);
  };

  React.useEffect(() => {
    const currentProtocol = window.location.protocol;
    refreshErrors();
    setLoadedProtocol(currentProtocol);
    return () => {
      setLoadedProtocol(null);
    };
  }, []);

  React.useEffect(() => {
    if (!isElectron && !isHttps) {
      const isPrivateNetwork = checkNetwork(xmlBackendIp);
      if (isPrivateNetwork) {
        setAuthErrorHint(AUTH_HINTS.CORS);
      }
    } else if (
      loginError === 't2259' &&
      !paramProxyConfig?.proxyAddress &&
      loadedProtocol === Protocol.https
    ) {
      setAuthErrorHint(AUTH_HINTS.CERTIFICATE);
    } else if (loginError === 't3171') {
      setIsStrongPassword(strongPassword);
      setIsPasswordResetModalOpen(true);
    }
  }, [loginError, strongPassword, xmlBackendIp, loadedProtocol]);

  React.useEffect(() => {
    if (changePasswordResponse === 't3178') {
      setIsPasswordResetModalOpen(false);
      setIsPasswordChanged(true);
    } else if (changePasswordResponse === 't3179') {
      setIsPasswordError(true);
      setPasswordError(t('t3179'));
    }
  }, [changePasswordResponse]);

  React.useEffect(() => {
    // If is running in the browser, always set the protocol to the protocol of the browser address
    // The browser will not accept http api communication on an https address
    if (!isElectron) {
      dispatch({ type: 'SET_PROTOCOL', payload: window.location.protocol });
    }
  }, []);

  React.useEffect(() => {
    if (xmlBackendIp) {
      saveXmlBackendIpToLocalStorage(xmlBackendIp);
    }
  }, [xmlBackendIp, state]);

  React.useEffect(() => {
    onSetIpError(hasIpError);
  }, [hasIpError]);

  React.useEffect(() => {
    // clear url to reduce visual clutter
    push({ search: '' });
  }, []);

  React.useEffect(() => {
    if (loadState === LoadStates.DONE || loginError) setIsFormBeingSent(false);
  }, [loadState, loginError]);

  const prefillForm = userInfo => {
    handleIpOnCreate(userInfo.ipAddress);
    setValue('user', userInfo.user);
    setValue('password', userInfo.password);
  };

  const openTerms = () => window.open(EULA_URL, '_blank');

  React.useEffect(() => {
    if (prefilledData) {
      prefillForm(prefilledData);
      setTimeout(() => {
        if (isEmbeddedApp) return;
        if (prefilledData.password) {
          loginButtonRef.current.click();
        } else {
          const passwordInput = document.getElementById('password');
          passwordInput.focus();
        }
      }, 0);
    }
  }, [prefilledData]);

  React.useEffect(() => {
    if (paramIp && paramPassword && paramUser) {
      prefillForm({
        ipAddress: paramIp,
        user: paramUser,
        password: paramPassword,
      });
    }
  }, [paramIp, paramPassword, paramUser]);

  const formInputStyles = {
    root: { m: `${theme.spacing.xs}px 0` },
    input: { height: `42px` },
    label: { transform: 'translate(8px, 4px)' },
  };

  return (
    <>
      {((hasLoginError && loginError !== 't3171') || hasIpError) && (
        <Alert
          type="error"
          message={
            loginError === 't3117'
              ? t(loginError, { targetLevel: targetAccessLevel })
              : t(loginError) || (hasIpError ? t('t922') : null)
          }
          closable={true}
          styles={{ root: { position: 'absolute', top: 0, left: 0 } }}
          props={{ root: { testId: 'loginError' } }}
        />
      )}
      {isPasswordChanged && (
        <Alert
          type="success"
          message={t('t3178')}
          closable={true}
          styles={{ root: { position: 'absolute', top: 0, left: 0 } }}
          props={{ root: { testId: 'changePasswordSuccess' } }}
        />
      )}
      <Form
        onSubmit={handleSubmit(handleOnSubmit)}
        styles={{
          root: {
            position: 'relative',
            p: `${isEmbeddedApp ? theme.spacing.xlg : 0}px ${
              theme.spacing.xlg
            }px`,
          },
        }}
      >
        {!isEmbeddedApp && (
          <>
            <Div
              id="protocolToggler"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={`${theme.spacing.sm}px`}
            >
              <Div>{isHttps ? 'HTTPS' : 'HTTP'}</Div>
              <Toggle
                name="https"
                isChecked={isHttps}
                onChange={handleProtocolChange}
                isLarge={true}
              />
            </Div>
            <Controller
              control={control}
              name="ip"
              render={({ onBlur, value, onChange }) => (
                <SelectInputCreatable
                  name="ip"
                  disabled={isBusy}
                  label={t('t677')}
                  searchable={true}
                  onBlur={onBlur}
                  onChange={(option, ...rest) => {
                    if (option) {
                      setIp((option as SelectInputOption).value);
                      onChange(option, ...rest);
                      refreshErrors();
                    }
                  }}
                  value={value}
                  onCreateOption={handleIpOnCreate}
                  options={localStorageXmlBackendIps.map(ip => ({
                    value: ip,
                    label: ip,
                  }))}
                  styles={formInputStyles}
                />
              )}
            />
          </>
        )}
        {!isEmbeddedApp && authErrorHint && (
          <Alert
            type="warning"
            message={
              <S.Hint>
                {errorHints(DESKTOP_INSTALLER_URL, xmlBackendIp)[authErrorHint]}
              </S.Hint>
            }
            styles={{
              root: {
                mb: 0,
              },
              iconContainer: {
                alignSelf: 'baseline',
                pt: `${theme.spacing.sm}px`,
                pr: `${theme.spacing.xs}px`,
              },
            }}
          />
        )}
        <TextInput
          name="user"
          label={t('t923')}
          tabIndex={0}
          // @ts-ignore
          elRef={register()}
          disabled={isBusy}
          styles={formInputStyles}
        />
        <PasswordInput
          id="password"
          name="password"
          label={t('t72')}
          disabled={isBusy}
          // @ts-ignore
          elRef={register()}
          styles={formInputStyles}
        />
        <P mt={theme.spacing.lg} fontSize={theme.typography.htmlFontSize}>
          {t('t3511')}
        </P>
        <A
          onClick={openTerms}
          display="inline-block"
          fontWeight={700}
          mt={`${theme.spacing.xs}px`}
          textDecoration="underline"
        >
          <Span cursor="pointer">{t('t3584')}</Span>
        </A>
        <Div textAlign="right">
          <Button
            elRef={loginButtonRef}
            testId="loginButton"
            id="loginButton"
            value="loginButton"
            type="submit"
            variant="primary"
            tabIndex={0}
            disabled={isBusy || !xmlBackendIp}
            minWidth={100}
            m={`${theme.spacing.sm}px 0 ${theme.spacing.xlg}px`}
            p={`${theme.spacing.xs}px`}
          >
            {isBusy && !isPasswordResetModalOpen && (
              <Spinner
                size={0}
                isTransparent={true}
                styles={{ root: { pr: theme.spacing.sm } }}
              />
            )}
            {t('t924')}
          </Button>
        </Div>
      </Form>
      <Modal
        isOpen={isPasswordResetModalOpen}
        onClose={onPasswordResetModalClose}
        header={<Modal.Header title={t('t3180')} description={t('t3171')} />}
      >
        <Form
          onSubmit={handleSubmit(handleOnSubmit)}
          styles={{
            root: { mt: theme.spacing.xxlg, position: 'relative' },
          }}
        >
          <PasswordInput
            name="newPassword"
            label={t('t3172')}
            disabled={isBusy}
            // @ts-ignore
            elRef={register()}
            styles={{
              root: { mt: theme.spacing.lg, mb: theme.spacing.lg },
            }}
            error={isPasswordError && { errorMessage: '' }}
          />
          <PasswordInput
            name="confirmPassword"
            label={t('t3173')}
            disabled={isBusy}
            // @ts-ignore
            elRef={register()}
            styles={{
              root: { mt: theme.spacing.lg, mb: theme.spacing.lg },
            }}
            error={isPasswordError && { errorMessage: passwordError }}
          />
          <Div display="flex">
            <Button
              variant="secondary"
              onClick={onPasswordResetModalClose}
              block={true}
              mr={`${theme.spacing.md}px`}
            >
              {t('t45')}
            </Button>
            <Button
              id="modalSubmit"
              value="modalSubmit"
              type="submit"
              variant="primary"
              block={true}
              tabIndex={0}
              disabled={isBusy}
            >
              {isBusy && (
                <Spinner
                  size={0}
                  isTransparent={true}
                  styles={{ root: { pr: theme.spacing.sm } }}
                />
              )}
              {t('t924')}
            </Button>
          </Div>
        </Form>
      </Modal>
    </>
  );
};
