import { User, Unit } from '@danfoss/etui-sm-xml';
import { AlarmType } from '../constants';
import { fetchAlarmRefsByUnit } from './fetch-alarm-refs-by-unit';

export async function fetchAlarmRefsByUnits([
  url,
  alarmType,
  user,
  units,
  skipSessionUpdate = false,
]: [string, AlarmType, User, Unit[], boolean]): Promise<string[]> {
  const data = await Promise.all(
    units.map(unit =>
      fetchAlarmRefsByUnit(url, alarmType, user, unit, skipSessionUpdate),
    ),
  );

  return data.flat();
}
