import * as React from 'react';
import debounce from 'lodash.debounce';
import { Label } from '@danfoss/etui-system-elements';
import { useTheme } from 'styled-components';
import * as S from './styles';

interface ColorPickerProps {
  name: string | number;
  defaultValue: string;
  onChange: (value: string) => void;
  label?: string;
  debounceDelay?: number;
}

export function ColorPicker({
  name,
  defaultValue,
  onChange,
  label,
  debounceDelay = 50,
}: ColorPickerProps) {
  const theme = useTheme();
  const [color, setColor] = React.useState(defaultValue);

  const debouncedSetColor = debounce(value => {
    setColor(value);
    onChange(value);
  }, debounceDelay);

  const handleChange = e => {
    e.persist();
    const newColor = e.target.value;
    debouncedSetColor(newColor);
  };

  return (
    <Label
      style={{ cursor: 'pointer' }}
      htmlFor={`colorPicker-${name}`}
      fontSize="12px"
    >
      {label}
      <S.ColorPicker bg={color} mt={label ? `${theme.spacing.xxs}px` : 0} />
      <input
        id={`colorPicker-${name}`}
        name={`colorPicker-${name}`}
        type="color"
        style={{
          opacity: 0,
          position: 'absolute',
          width: '1px',
          height: '1px',
          flex: 1,
        }}
        onChange={handleChange}
        value={color}
      />
    </Label>
  );
}
