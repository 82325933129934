import {
  BuildXMLOptions,
  fetchXMLData,
  getUnitUrl,
  Unit,
  User,
  XML_ACTION,
  XML_TAG,
} from '@danfoss/etui-sm-xml';
import { TreeTableItem } from '@danfoss/etui-core';
import { TFunction } from 'i18next';
import {
  ConfigureDeviceResponse,
  ConfigureDeviceStatusResponse,
  DEVICE_TYPE,
  DiscoveryTableData,
} from '../types';
import { findDeviceByDeviceId } from './suction-group-flow-action';

export interface StartConfigRefrigDeviceProps {
  url: string;
  user: User;
  refrigLayoutFinalTableData: DiscoveryTableData[];
  compressorControlData: TreeTableItem[];
  t: TFunction;
  unit: Unit;
}

export const startConfigRefrigDevice = async ({
  url,
  user,
  refrigLayoutFinalTableData,
  compressorControlData,
  t,
  unit,
}: StartConfigRefrigDeviceProps) => {
  const { finalDevicesToConfigureXmlTags, totalDevices } = getConfigDevicesXml(
    refrigLayoutFinalTableData,
    compressorControlData,
    t,
  );
  try {
    const data: ConfigureDeviceResponse =
      await fetchXMLData<ConfigureDeviceResponse>({
        url: getUnitUrl(url, unit),
        attributes: {
          user: user?.user,
          password: user?.password,
          lang: user?.language,
          storeview_only: '1',
          version: 'C',
          total: totalDevices,
          action: XML_ACTION.CONFIGURE_DEVICE,
        },
        items: finalDevicesToConfigureXmlTags,
      });
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

const getDeviceXML = (
  deviceType: XML_TAG,
  currentDevice: DiscoveryTableData,
  t: TFunction,
) => {
  let { name } = currentDevice;
  if (!name.length) {
    name = t('t492');
  }
  const deviceXmlTag: BuildXMLOptions = {
    tag: deviceType,
    attributes: {
      name: name || '',
      type: currentDevice?.deviceType || '',
      arg1: currentDevice?.dim1 || '0',
      arg2: currentDevice?.dim2 || '0',
      arg3: currentDevice?.dim3 || '0',
      rk: currentDevice?.rk.toString() || '',
      sg: currentDevice?.sg.toString() || '',
      rel_sg: currentDevice?.rel_sg || '',
      index: currentDevice?.model_idx,
      model: currentDevice?.model || '',
      filename: currentDevice?.file || '',
      version: currentDevice?.version || '',
      device: currentDevice?.device || '',
      addr: currentDevice?.address || '',
      num_sg:
        currentDevice.num_sg !== undefined && deviceType === XML_TAG.SUCTION
          ? currentDevice?.num_sg
          : '',
      ip:
        currentDevice.ip !== undefined && currentDevice?.ip?.length > 2
          ? currentDevice?.ip
          : '',
    },
  };
  return deviceXmlTag;
};

const checkAndFrameDeviceXml = (
  device: TreeTableItem,
  refrigLayoutFinalTableData: DiscoveryTableData[],
  t: TFunction,
) => {
  const currentDevice = findDeviceByDeviceId(
    device.id,
    refrigLayoutFinalTableData,
  );
  if (isCircuitDevice(currentDevice.deviceType)) {
    const circuitXmlTag: BuildXMLOptions = getDeviceXML(
      XML_TAG.CIRCUIT,
      currentDevice,
      t,
    );
    return circuitXmlTag;
  }
  if (isSuctionDevice(currentDevice.deviceType)) {
    const suctionXmlTag: BuildXMLOptions = getDeviceXML(
      XML_TAG.SUCTION,
      currentDevice,
      t,
    );
    return suctionXmlTag;
  }

  return null;
};

const isCircuitDevice = (deviceType: string) => {
  switch (deviceType) {
    case DEVICE_TYPE.CIRCUIT:
    case DEVICE_TYPE.GEN_CIRCUIT:
      return true;
    default:
      return false;
  }
};

const isSuctionDevice = (deviceType: string) => {
  switch (deviceType) {
    case DEVICE_TYPE.RACK:
    case DEVICE_TYPE.RACKSUCT:
    case DEVICE_TYPE.GEN_PACK:
    case DEVICE_TYPE.GEN_SUCT:
    case DEVICE_TYPE.NO_COMP:
    case DEVICE_TYPE.NO_COMP_SUCT:
      return true;
    default:
      return false;
  }
};

const isChildrenEmpty = (packDevice: TreeTableItem) =>
  packDevice?.items?.length === 0;

const getConfigDevicesXml = (
  refrigLayoutFinalTableData: DiscoveryTableData[],
  compressorControlData: TreeTableItem[],
  t: TFunction,
) => {
  const refrigLayoutXmlTags: BuildXMLOptions[] = [];
  let total: number = 0;

  const { updatedRefrigLayoutFinalTableData } = updateRelSgForDevices(
    refrigLayoutFinalTableData,
    compressorControlData,
  );

  const finalSuctionXmlTag: BuildXMLOptions = {
    tag: XML_TAG.SUCTION,
    items: [],
  };
  const finalCircuitXmlTag: BuildXMLOptions = {
    tag: XML_TAG.CIRCUIT,
    items: [],
  };
  compressorControlData.forEach(packDevice => {
    const deviceXMLTag = checkAndFrameDeviceXml(
      packDevice,
      updatedRefrigLayoutFinalTableData,
      t,
    );
    total++;
    finalSuctionXmlTag.items.push(deviceXMLTag);
    if (isChildrenEmpty(packDevice) === false) {
      for (const suctionDevice of packDevice?.items) {
        const suctionXMLTag = checkAndFrameDeviceXml(
          suctionDevice,
          refrigLayoutFinalTableData,
          t,
        );
        finalSuctionXmlTag.items.push(suctionXMLTag);
        total++;
      }
    }
  });

  compressorControlData.forEach(packDevice => {
    if (isChildrenEmpty(packDevice) === false) {
      packDevice?.items?.forEach(suctionDevice => {
        if (isChildrenEmpty(suctionDevice) === false) {
          suctionDevice?.items?.forEach(caseDevice => {
            const caseDeviceXMLTag = checkAndFrameDeviceXml(
              caseDevice,
              updatedRefrigLayoutFinalTableData,
              t,
            );
            total++;
            finalCircuitXmlTag.items.push(caseDeviceXMLTag);
            if (isChildrenEmpty(caseDevice) === false) {
              caseDevice?.items?.forEach(multiCaseDevice => {
                const multiCaseDeviceXMLTag = checkAndFrameDeviceXml(
                  multiCaseDevice,
                  updatedRefrigLayoutFinalTableData,
                  t,
                );
                total++;
                finalCircuitXmlTag.items.push(multiCaseDeviceXMLTag);
              });
            }
          });
        }
      });
    }
  });

  refrigLayoutXmlTags.push(finalSuctionXmlTag);
  refrigLayoutXmlTags.push(finalCircuitXmlTag);

  return {
    finalDevicesToConfigureXmlTags: refrigLayoutXmlTags,
    totalDevices: total,
  };
};

export const updateRelSgForDevices = (
  refrigLayoutFinalTableData: DiscoveryTableData[],
  compressorControlData: TreeTableItem[],
) => {
  const finalTableData: DiscoveryTableData[] = [];
  let rk = 1;
  let sg = 1;
  compressorControlData.forEach(packDevices => {
    const packDevice = findDeviceByDeviceId(
      packDevices.id,
      refrigLayoutFinalTableData,
    );
    if (packDevice.rk !== rk) {
      packDevice.rk = rk;
    }
    packDevice.sg = sg;
    packDevice.dim1 = '0';
    packDevice.dim2 = '0';
    packDevice.dim3 = '0';
    packDevice.rel_sg = '1';
    finalTableData.push(packDevice);
    rk++;
    if (packDevices?.items?.length) {
      let rel_sg = 1;
      packDevices?.items?.forEach(suctionDevices => {
        const suctionDevice = findDeviceByDeviceId(
          suctionDevices.id,
          refrigLayoutFinalTableData,
        );
        suctionDevice.rk = packDevice.rk;
        suctionDevice.sg = sg;
        suctionDevice.dim1 = suctionDevice.sg.toString();
        suctionDevice.dim2 = '0';
        suctionDevice.dim3 = '0';
        suctionDevice.rel_sg = rel_sg.toString();
        rel_sg++;
        sg++;
        finalTableData.push(suctionDevice);
        if (suctionDevices?.items?.length) {
          let caseDim2 = 1;
          const caseDim3 = '1';
          suctionDevices?.items?.forEach(caseDevices => {
            const caseDevice = findDeviceByDeviceId(
              caseDevices.id,
              refrigLayoutFinalTableData,
            );
            caseDevice.dim1 = suctionDevice.dim1;
            caseDevice.dim2 = caseDim2.toString();
            caseDevice.dim3 = caseDim3;
            caseDevice.rk = suctionDevice.rk;
            caseDevice.sg = suctionDevice.sg;
            caseDevice.rel_sg = suctionDevice.rel_sg.toString();
            caseDim2++;
            finalTableData.push(caseDevice);
            if (caseDevices?.items?.length) {
              let multiCaseDim3 = 2;
              caseDevices?.items?.forEach(multiCases => {
                const multiCaseDevice = findDeviceByDeviceId(
                  multiCases.id,
                  refrigLayoutFinalTableData,
                );
                multiCaseDevice.dim1 = caseDevice.dim1;
                multiCaseDevice.dim2 = caseDevice.dim2;
                multiCaseDevice.dim3 = multiCaseDim3.toString();
                multiCaseDevice.rk = suctionDevice.rk;
                multiCaseDevice.sg = suctionDevice.sg;
                multiCaseDevice.rel_sg = suctionDevice.rel_sg.toString();
                finalTableData.push(multiCaseDevice);
                multiCaseDim3++;
              });
            }
          });
        }
      });
    } else {
      sg++;
    }
  });
  refrigLayoutFinalTableData.forEach(device => {
    if (
      device.deviceType === DEVICE_TYPE.CIRCUIT ||
      device.deviceType === DEVICE_TYPE.GEN_CIRCUIT
    ) {
      const existsInFinalTableData = finalTableData.some(
        finalDevice => finalDevice.deviceId === device.deviceId,
      );
      if (!existsInFinalTableData) {
        finalTableData.push(device);
      }
    }
  });
  return {
    updatedRefrigLayoutFinalTableData: finalTableData,
  };
};

export const getConfigStatus = async ([url, user, unit]: [
  string,
  User,
  Unit,
]) => {
  try {
    const data: ConfigureDeviceStatusResponse = await fetchXMLData({
      url: getUnitUrl(url, unit),
      attributes: {
        user: user?.user,
        password: user?.password,
        lang: user?.language,
        storeview_only: '1',
        version: 'C',
        action: XML_ACTION.READ_CONFIGURE_STATUS,
      },
    });
    return data;
  } catch (e) {
    throw new Error(e);
  }
};
