import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useTheme } from '@danfoss/etui-system';
import useSWR from 'swr';
import {
  addFieldRecursively,
  flattenData,
  IconSize,
  TreeviewSelect,
  useResponsive,
} from '@danfoss/etui-core';
import { useXmlResource, useAuth, useUnit } from '@danfoss/etui-sm/context';
import { getDeviceIdAndDeviceTypeByEquipmentId } from 'utils';
import { fetchGroupedDevicesByDeviceType } from 'actions';
import { routePaths } from 'routePaths';
import { EquipmentTreeviewSelectItem } from './EquipmentTreeviewSelectItem';
import { getTextAfterFirstUnderscore } from './utils/getTextAfterFirstUnderscore';

const FIELD_TO_DISPLAY = 'fullName';

function EquipmentTreeviewSelect() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { equipmentId } = useParams<{ equipmentId: string }>();
  const { push } = useHistory();
  const location = useLocation();
  const { deviceType } = getDeviceIdAndDeviceTypeByEquipmentId(equipmentId);
  const { units } = useUnit();

  const { url: xmlBackendURL } = useXmlResource();
  const { user } = useAuth();

  const [preselectedItem, setPreselectedItem] = React.useState(null);

  const { screenIsAtMost } = useResponsive({
    xs: parseInt(theme.breakpoints[0], 10),
  });
  const isXsView = screenIsAtMost('xs');

  const customStyles = React.useMemo(
    () => ({
      wrapper: {
        marginLeft: `${theme.spacing.md}px`,
        height: isXsView ? '50px' : '60px',
        width: '250px',
        borderLeft: `1px solid ${theme.palette.grey[100]}`,
        borderRight: `1px solid ${theme.palette.grey[100]}`,
      },
      selectContent: {
        backgroundColor: '#484848',
        color: theme.palette.common.white,
        width: '180px',
      },
      main: {
        backgroundColor: theme.palette.black[80],
      },
      options: {
        backgroundColor: '#484848',
        color: theme.palette.common.white,
        top: isXsView ? '50px' : '60px',
        maxHeight: '80vh',
        overflow: 'auto',
      },
    }),
    [isXsView],
  );

  const onSelectItem = item => {
    const id = `${item.deviceType}_${item.id}`;
    const currentTab = location.pathname.split('/').pop();

    if (item !== preselectedItem) {
      setPreselectedItem(item);
      push(`${routePaths.pages.equipment.listPath}/${id}/${currentTab}`);
    }
  };

  const { data = [] } = useSWR(
    () => (units.length ? [xmlBackendURL, deviceType, user, units] : null),
    fetchGroupedDevicesByDeviceType,
    {
      shouldRetryOnError: false,
    },
  );

  const processedData = React.useMemo(
    () =>
      addFieldRecursively(data, 'items', FIELD_TO_DISPLAY, (item, name) =>
        name ? `${name} / ${item.name}` : name + item.name,
      ),
    [data.length],
  );

  const allItems = React.useMemo(
    () => flattenData(processedData),
    [processedData],
  );

  React.useEffect(() => {
    if (!processedData) return;
    const preselectedItemId = getTextAfterFirstUnderscore(equipmentId);
    const result = allItems.find(item => item.id === preselectedItemId);
    setPreselectedItem(result);
  }, [processedData, equipmentId]);

  return preselectedItem ? (
    <TreeviewSelect
      key={preselectedItem.id}
      customStyles={customStyles}
      iconProps={{ size: 16 as IconSize, color: theme.palette.black[10] }}
      data={processedData}
      displayKey={FIELD_TO_DISPLAY}
      label={t('t963')}
      onSelectItem={onSelectItem}
      preselectedItem={preselectedItem}
      rowTemplate={props => <EquipmentTreeviewSelectItem {...props} />}
    />
  ) : null;
}

export { EquipmentTreeviewSelect };
