import { styled } from '@danfoss/etui-system';
import { Div, DivProps } from '@danfoss/etui-system-elements';

type IProps = DivProps & {
  isMdView: boolean;
};

export const EmbeddedFormContainer = styled(Div)<IProps>`
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 0 5px 0 lightgray;
  margin-top: ${({ theme }) => theme.spacing.md}px;
  width: ${({ isMdView }) => (isMdView ? 342 : 460)}px;
`;
