export const animatedCompressorSvg = `
<svg xmlns="http://www.w3.org/2000/svg" width="59" height="64" viewBox="0 0 59 64" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.5332 0.81543C14.0007 0.81543 13.5691 1.24707 13.5691 1.77952V17.8091C6.61213 22.2641 2 30.0614 2 38.9337C2 52.775 13.225 64 27.0663 64C40.9076 64 52.1326 52.775 52.1326 38.9337C52.1326 30.0614 47.5205 22.2641 40.5636 17.8091V1.77952C40.5636 1.24707 40.1319 0.81543 39.5995 0.81543H14.5332Z" fill="#484848"/>
<path d="M40 7H52.8965C54.6112 7 56 9.14278 56 11.7886V64" stroke="#484848" stroke-width="2" stroke-miterlimit="10"/>
<path d="M14 7H4.32763C3.04157 7 2 6.77444 2 6.49594V1" stroke="#484848" stroke-width="2" stroke-miterlimit="10"/>
<g>
<path transform-origin="center 0" d="M23.5434 36.7459L25.1472 17.5H28.5655L30.1693 36.7459H23.5434Z" fill="#BFBEBE" stroke="white">  
<animateTransform attributeName="transform" type="rotate" values="0; -4; 0; 4; 0" dur="1s" repeatCount="indefinite"/>
</path>
<mask id="path-7-inside-1_2838_5714" fill="white">
<path fill-rule="evenodd" clip-rule="evenodd" d="M36 7C36 6.44772 35.5523 6 35 6H19C18.4477 6 18 6.44772 18 7V8.89258V9.85636V16.5694C18 17.1217 18.4477 17.5694 19 17.5694H35C35.5523 17.5694 36 17.1217 36 16.5694V9.85636V8.89258V7Z"/>
</mask>
<path fill-rule="evenodd" clip-rule="evenodd" d="M36 7C36 6.44772 35.5523 6 35 6H19C18.4477 6 18 6.44772 18 7V8.89258V9.85636V16.5694C18 17.1217 18.4477 17.5694 19 17.5694H35C35.5523 17.5694 36 17.1217 36 16.5694V9.85636V8.89258V7Z" fill="#BFBEBE"/>
<path d="M19 7H35V5H19V7ZM19 8.89258V7H17V8.89258H19ZM19 9.85636V8.89258H17V9.85636H19ZM19 16.5694V9.85636H17V16.5694H19ZM35 16.5694H19V18.5694H35V16.5694ZM35 9.85636V16.5694H37V9.85636H35ZM35 8.89258V9.85636H37V8.89258H35ZM35 7V8.89258H37V7H35ZM17 16.5694C17 17.674 17.8954 18.5694 19 18.5694V16.5694H19H17ZM35 7H37C37 5.89543 36.1046 5 35 5V7ZM35 18.5694C36.1046 18.5694 37 17.674 37 16.5694H35V18.5694ZM19 5C17.8954 5 17 5.89543 17 7H19V5Z" fill="white" mask="url(#path-7-inside-1_2838_5714)"/>
<path d="M19.0372 9.51562H35.1146" stroke="white"/>
<circle cx="27" cy="13" r="1" fill="#484848"/>
<animateTransform attributeName="transform" type="translate" values="0 0; 0 7; 0 0" dur="1s" repeatCount="indefinite"/>
</g>
<circle cx="27.1832" cy="45.8766" r="9.68319" fill="#BFBEBE" stroke="white"/>
<circle transform-origin="27 46" cx="27.1832" cy="43.0995" r="3.20298" fill="#BFBEBE" stroke="white">
<animateTransform attributeName="transform" type="rotate" from="0" to="360" dur="1s" repeatCount="indefinite"/>
</circle>
</svg>`;

export const compressorSvg = `
<svg xmlns="http://www.w3.org/2000/svg" width="59" height="64" viewBox="0 0 59 64" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.5332 0.81543C14.0007 0.81543 13.5691 1.24707 13.5691 1.77952V17.8091C6.61213 22.2641 2 30.0614 2 38.9337C2 52.775 13.225 64 27.0663 64C40.9076 64 52.1326 52.775 52.1326 38.9337C52.1326 30.0614 47.5205 22.2641 40.5636 17.8091V1.77952C40.5636 1.24707 40.1319 0.81543 39.5995 0.81543H14.5332Z" fill="#484848"/>
<path d="M40 7H52.8965C54.6112 7 56 9.14278 56 11.7886V64" stroke="#484848" stroke-width="2" stroke-miterlimit="10"/>
<path d="M14 7H4.32763C3.04157 7 2 6.77444 2 6.49594V1" stroke="#484848" stroke-width="2" stroke-miterlimit="10"/>
<path  d="M23.5434 36.7459L25.1472 17.5H28.5655L30.1693 36.7459H23.5434Z" fill="#BFBEBE" stroke="white" />
<mask id="path-7-inside-1_2838_5714" fill="white">
<path fill-rule="evenodd" clip-rule="evenodd" d="M36 7C36 6.44772 35.5523 6 35 6H19C18.4477 6 18 6.44772 18 7V8.89258V9.85636V16.5694C18 17.1217 18.4477 17.5694 19 17.5694H35C35.5523 17.5694 36 17.1217 36 16.5694V9.85636V8.89258V7Z"/>
</mask>
<path fill-rule="evenodd" clip-rule="evenodd" d="M36 7C36 6.44772 35.5523 6 35 6H19C18.4477 6 18 6.44772 18 7V8.89258V9.85636V16.5694C18 17.1217 18.4477 17.5694 19 17.5694H35C35.5523 17.5694 36 17.1217 36 16.5694V9.85636V8.89258V7Z" fill="#BFBEBE"/>
<path d="M19 7H35V5H19V7ZM19 8.89258V7H17V8.89258H19ZM19 9.85636V8.89258H17V9.85636H19ZM19 16.5694V9.85636H17V16.5694H19ZM35 16.5694H19V18.5694H35V16.5694ZM35 9.85636V16.5694H37V9.85636H35ZM35 8.89258V9.85636H37V8.89258H35ZM35 7V8.89258H37V7H35ZM17 16.5694C17 17.674 17.8954 18.5694 19 18.5694V16.5694H19H17ZM35 7H37C37 5.89543 36.1046 5 35 5V7ZM35 18.5694C36.1046 18.5694 37 17.674 37 16.5694H35V18.5694ZM19 5C17.8954 5 17 5.89543 17 7H19V5Z" fill="white" mask="url(#path-7-inside-1_2838_5714)"/>
<path d="M19.0372 9.51562H35.1146" stroke="white"/>
<circle cx="27" cy="13" r="1" fill="#484848"/>
<circle cx="27.1832" cy="45.8766" r="9.68319" fill="#BFBEBE" stroke="white"/>
<circle  cx="27.1832" cy="43.0995" r="3.20298" fill="#BFBEBE" stroke="white"/>
</svg>`;
