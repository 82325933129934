import React from 'react';
import { Div, Span } from '@danfoss/etui-system-elements';
import { Button, Checkbox, icons, Tooltip } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { useModal } from '@danfoss/etui-sm';
import { useRefrigLayout } from '../../context';
import { DiscoveryPageData } from '../../types/DiscoveryPage.types';
import { DiscoveryFlowRescanModal } from '.';

export interface DiscoveryFlowActionsProps {
  handleOfflineProgramming: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  onRescanComplete: (rescannedPageData: DiscoveryPageData) => void;
  handleOnRefresh: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
}

export const DiscoveryFlowActions = ({
  handleOfflineProgramming,
  onRescanComplete,
  handleOnRefresh,
}: DiscoveryFlowActionsProps) => {
  const { t } = useTranslation();
  const { isOfflineProgramming, discoveryTableData, isHvac, unit } =
    useRefrigLayout();

  const [showRescanModal] = useModal(DiscoveryFlowRescanModal, {
    onRescanComplete,
    isOfflineProgramming,
    discoveryTableData,
    isHvac,
    shouldStartRescan: true,
    unit,
  });
  const handleOnRescan = () => {
    showRescanModal();
  };
  return (
    <Div
      style={{
        float: 'right',
        display: 'inline-block',
        marginTop: '-12px',
      }}
      testId="discovery-flow-actions-div"
    >
      <Span style={{ marginRight: '10px' }}>
        <Checkbox
          disabled={!discoveryTableData?.length}
          id="discovery-offline-devices"
          checked={isOfflineProgramming}
          onChange={handleOfflineProgramming}
        />
        {` ${t('t513')}`}
      </Span>
      <Span
        testId="discovery-flow-refresh-span"
        style={{ marginRight: '10px' }}
      >
        <Tooltip placement="bottom" message={t('t3344')}>
          <Button
            disabled={!discoveryTableData?.length}
            iconOnly={true}
            iconProps={{ glyph: icons.REFRESH }}
            height="5px"
            onClick={handleOnRefresh}
          />
        </Tooltip>
      </Span>
      <Span style={{ marginRight: '20px' }}>
        <Button variant="secondary" width="150px" onClick={handleOnRescan}>
          {!discoveryTableData?.length ? t('t768') : t('t512')}
        </Button>
      </Span>
    </Div>
  );
};
