import * as React from 'react';
import {
  ConfigurationListItem as ConfigurationListItemProp,
  Unit,
} from '@danfoss/etui-sm-xml';
import { Div, Label } from '@danfoss/etui-system-elements';
import { useTheme } from '@danfoss/etui-system';
import {
  Icon,
  icons,
  Spinner,
  SpinnerSize,
  Tooltip,
  useResponsive,
} from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import {
  EditType,
  getEditType,
  getIsEditableByItem,
  TableField,
} from './utils';
import {
  ConfigurationListItemEdit,
  ConfigurationListItemEditProps,
  SaveFunc,
} from './ConfigurationListItemEdit';
import { ConfigurationListItemEditAction } from './ConfigurationListItemEditAction';
import { ConfigurationListItemAddress } from './ConfigurationItemContentList';
import * as S from './styles';

export type ConfigurationListItemProps = {
  index?: number;
  item: ConfigurationListItemProp;
  addresses?: ConfigurationListItemAddress[];
  unit: Unit;
  isLast?: boolean;
  isEditable?: boolean;
  isAuthorized?: boolean;
  htmlId: string;
  isSearchable?: boolean;
  isUpdating?: boolean;
  onSave: SaveFunc;
  itemRenderer?: (...args: any[]) => React.ReactNode;
  resetConfigSwrCache?: () => void;
  prefix?: string;
  isCopyControllerSelected?: boolean;
  isCopyControllerLoading?: boolean;
  handleBrowse?: (file: File, item?: ConfigurationListItemProp) => void;
  isCopyWizard?: boolean;
  isCopyLoading?: boolean;
  isWizards?: boolean;
  showTooltip?: boolean;
};

const shouldRenderFileRow = (
  item: ConfigurationListItemProp,
  isFilelist: boolean,
) =>
  isFilelist ||
  (item.li === '0' &&
    item.name.startsWith('Files') &&
    item.name.trim().split('   ').length > 1);

const ConfigurationListItem = React.memo(
  ({
    index,
    isAuthorized,
    isUpdating,
    item,
    addresses,
    unit,
    htmlId,
    onSave,
    itemRenderer,
    isSearchable,
    isEditable: isTabEditable,
    prefix = '',
    resetConfigSwrCache,
    isCopyControllerSelected,
    isCopyControllerLoading,
    handleBrowse,
    isCopyWizard,
    isCopyLoading,
    isWizards,
    showTooltip = true,
  }: ConfigurationListItemProps) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const copyControllerSelectionCompleted =
      isCopyControllerSelected && !isCopyControllerLoading;
    const editType = getEditType(item, copyControllerSelectionCompleted);
    const isItemTextOnly =
      item.eda === '0' && item.ef === '0' && (!item.value || item.value === '');
    const isItemEditable = isTabEditable && getIsEditableByItem(item);
    const isActionEditType = editType === EditType.Action;

    const isFilelist =
      item.ef === TableField.LIST_FILES ||
      item.ef === TableField.LIST_DEVICE_MANAGEMENT ||
      (item.name.startsWith('Files') && item.li === '0');

    const { screenIsAtMost } = useResponsive({
      sm: parseInt(theme.breakpoints[1], 10),
    });

    const isSmView = screenIsAtMost('sm');

    const renderName = () => {
      let res = null;
      let list: Array<string> = [];

      if (shouldRenderFileRow(item, isFilelist)) {
        list = [`${item.name}   ${item.value}`];
      }

      if (isFilelist) {
        res = list.map((val, idx) => {
          return (
            <S.PreWrapper key={idx}>
              <pre>{val}</pre>
            </S.PreWrapper>
          );
        });
      } else {
        const replacedName = item.name.replace('*', '');
        res = (
          <S.PreWrapper>
            <pre>{replacedName}</pre>
          </S.PreWrapper>
        );
      }
      return res;
    };

    const editControlRenderer = (
      overrides?: Partial<ConfigurationListItemEditProps>,
    ) => {
      return (
        <Div
          display={isSmView ? 'flex' : 'block'}
          width={['29%', '49%', '49%', '49%']}
          justifyContent={isSmView ? 'flex-end' : 'flex-start'}
          p={`0 ${theme.spacing.xs}px`}
          flex="1"
        >
          <ConfigurationListItemEdit
            isSearchable={isSearchable}
            item={item}
            addresses={addresses}
            unit={unit}
            onSave={onSave}
            editType={editType}
            id={htmlId}
            index={index}
            isEditable={isItemEditable}
            isTextOnly={isItemTextOnly}
            isAuthorized={isAuthorized}
            isFilelist={isFilelist}
            prefix={prefix}
            resetConfigSwrCache={resetConfigSwrCache}
            {...(overrides || null)}
            isCopyControllerSelected={isCopyControllerSelected}
            handleBrowse={handleBrowse}
            isCopyWizard={isCopyWizard}
            isCopyLoading={isCopyLoading}
            isWizards={isWizards}
          />
        </Div>
      );
    };

    const defaultItemRender = !isActionEditType ? (
      <>
        {item.old === '1' &&
        item.eda === '1' &&
        item.name.includes('*') &&
        showTooltip ? (
          <Div ml={`${theme.spacing.spacingUnit - 27}px`}>
            <Tooltip
              message={item.online ? t('t3214') : t('t3298')}
              placement="left"
            >
              {isUpdating ? (
                <Div testId="spinner-loading">
                  <Spinner size={SpinnerSize.xSmall} />
                </Div>
              ) : (
                <Div testId="note-icon">
                  <Icon glyph={icons.NOTE_IC} />
                </Div>
              )}
            </Tooltip>
          </Div>
        ) : null}
        <Div
          display="flex"
          width="100%"
          p="0 16px 0 0"
          maxWidth={['50%', '70%', '70%', '60%']}
        >
          <Label
            htmlFor={htmlId}
            maxWidth={['100%', '70%', '100%', '100%']}
            p={
              parseInt(item.ind, 10) !== 0
                ? `0 ${theme.spacing.xs}px 0 ${
                    parseInt(item.ind, 10) * theme.spacing.md
                  }px`
                : `0 ${theme.spacing.xs}px`
            }
          >
            {renderName()}
          </Label>
        </Div>
        {editControlRenderer()}
      </>
    ) : (
      <Div
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Div width={['80%', '60%']}>
          <ConfigurationListItemEditAction
            item={item}
            onSave={onSave}
            isAuthorized={isAuthorized}
            isEditable={isItemEditable}
          />
        </Div>
      </Div>
    );

    return (
      <Div
        testId="configuration-list-item"
        borderBottom={`1px solid ${theme.palette.divider}`}
        fontSize={theme.typography.fontSizeBase}
        fontWeight={theme.typography.fontWeightBold}
        color={theme.palette.text.secondary}
      >
        <Div
          display="flex"
          alignItems="center"
          justifyContent={isSmView ? 'space-between' : 'flex-start'}
          height={isActionEditType ? '50px' : '42px'}
          width={['100%', '100%', '80%', '60%']}
          maxWidth={['100%', '100%', '1000px', '1000px']}
          m="0 auto"
          p={0}
        >
          {itemRenderer
            ? itemRenderer(
                item,
                defaultItemRender,
                editControlRenderer,
                renderName,
              )
            : defaultItemRender}
        </Div>
      </Div>
    );
  },
);

export { ConfigurationListItem };
