import { fetchXMLData, fetchXMLFileData, csvFileToJson } from '@danfoss/etui-sm-xml';
import { _ as _objectSpread2 } from '../../../_rollupPluginBabelHelpers-4e4a2de3.js';
import { XML_ACTION } from '@danfoss/etui-sm-xml/constants';

async function fetchHaccpReport(_, user, url, from, to) {
  const getSharedAttributes = () => {
    return {
      user: user.user,
      password: user.password,
      lang: user.language,
      storeview_only: '1',
      version: 'C'
    };
  };
  const {
    operation,
    query_id,
    error_msg
  } = await fetchXMLData({
    url,
    attributes: _objectSpread2(_objectSpread2({}, getSharedAttributes()), {}, {
      action: XML_ACTION.START_HISTORY_QUERY,
      simple: '1',
      hist_file: '1',
      haccp_file: '1',
      start_epoch: from,
      stop_epoch: to
    })
  });
  if (!operation || operation !== 'Succeed') {
    throw new Error(`Response from requesting history query was: ${operation || error_msg || 'unknown'}`);
  }
  const promise = async () => {
    let status = 'active';
    const maxStatusCounts = 120;
    let attemptedStatusCounts = 0;
    do {
      // eslint-disable-next-line no-await-in-loop
      const {
        status: tempStatus,
        error_msg: error
      } = await fetchXMLData({
        url,
        attributes: _objectSpread2(_objectSpread2({}, getSharedAttributes()), {}, {
          action: XML_ACTION.READ_QUERY_STATUS,
          query_id
        })
      });
      status = tempStatus;
      if (error) {
        status = error;
      }
      if (status === 'complete') {
        return status;
      }
      attemptedStatusCounts += 1;
      if (attemptedStatusCounts > maxStatusCounts) {
        status = 'max status attempts reached';
      }
      // eslint-disable-next-line no-await-in-loop
      await new Promise(resolve => {
        setTimeout(resolve, 100);
      });
    } while (status === 'active');
    throw new Error(`The output of reading the query status was ${status}`);
  };
  const status = await promise();

  // access_area is mandatory after 3.2 firmware
  // old firmware will ignore the access_area attribute
  const csvFile = await fetchXMLFileData({
    action: XML_ACTION.GET_FILE,
    offset: 0,
    attributes: {
      offset: 0,
      filename: 'haccp_report.csv',
      access_area: 'user'
    },
    url,
    user: user.user,
    password: user.password,
    lang: user.language
  });

  // Decode csvFile from iso ISO-8859-1 to utf-8
  csvFile.fileContent = decodeURIComponent(escape(csvFile.fileContent));
  const jsonFile = await csvFileToJson(csvFile.fileContent);
  return jsonFile && jsonFile.length > 0 ? {
    jsonFile,
    csvFile,
    operation,
    query_id,
    status
  } : null;
}

export { fetchHaccpReport };
