import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectInput, SelectInputOption } from '@danfoss/etui-core';
import { useTheme } from '@danfoss/etui-system';

interface ConfigurationItemContentMenuPageSelectProps {
  multipage: string;
  isFullWidth?: boolean;
  onPageChange: (page: string) => void;
  selectedPage?: string;
}

const ConfigurationItemContentMenuPageSelect = ({
  multipage,
  onPageChange,
  isFullWidth = false,
  selectedPage,
}: ConfigurationItemContentMenuPageSelectProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const getOptions = React.useCallback((): SelectInputOption[] => {
    const options = [];
    for (let index = 1; index <= parseInt(multipage, 10); index++) {
      const option: SelectInputOption = {
        label: `${index}`,
        value: `${index - 1}`,
      };
      options.push(option);
    }
    return options;
  }, [multipage, onPageChange]);

  const options = getOptions();
  const page = options.find(o => o.value === selectedPage);

  const handlePageChange = (option: { label: string; value: string }) => {
    const { value } = option;
    onPageChange(value);
  };

  return (
    <SelectInput
      label={t('t282')}
      name="pageSelect"
      value={page || options[0]}
      options={getOptions()}
      onChange={handlePageChange}
      styles={{
        root: {
          width: isFullWidth ? '100%' : '15%',
          borderRight: `1px solid ${theme.palette.divider}`,
        },
        input: { borderColor: 'transparent', boxShadow: 'none' },
        menu: { borderColor: 'transparent', top: '100%' },
        label: { width: 'fit-content' },
      }}
    />
  );
};

export { ConfigurationItemContentMenuPageSelect };
