const getFormatedDate = () => {
  const now = new Date().toISOString();
  const [date, time] = now.split('T');
  return `${date.replace(/-/g, '_')}_${time.slice(0, -5).replace(/:/g, '_')}`;
};

export const downloadFile = (content: string) => {
  const now = getFormatedDate();
  const link = document.createElement('a');
  link.href = content;
  link.download = `address_book_${now}.json`;
  link.click();
};
