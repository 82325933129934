import { TreeItem } from '@danfoss/etui-core';
import { AxisType, ChartData, Legend, Ptype } from '../HistoryPage.types';
import { HISTORY_MARKER } from './constants';

export enum AmPm {
  AM = 'AM',
  PM = 'PM',
}

// tweaked from https://stackoverflow.com/a/34402261/5531166
// ^                # Start of the string
//  (?:             # NCG1
//    (?:           # NCG2
//      0\d         # A zero followed by a single number between zero and nine
//      |           # OR
//      1\d         # A literal '1' followed by a single number between zero and nine
//      |           # OR
//      2[0-3]      # A literal '2' followed by a single number between zero and three
//     )            # CLOSE NCG2
//     :            # A literal colon ':'
//     [0-5]\d      # A single number from 0 to 5 followed by any digit
//   )              # CLOSE NCG1
// $                # End of the string
export const timeRegex24 = /^(?:(?:0?\d|1\d|2[0-3]):[0-5]\d)$/;
// ^                # Start of the string
//  (?:             # NCG1
//    (?:           # NCG2
//      \d          # Single number between zero and nine
//      |           # OR
//      1[0-2]      # A literal '1' followed by a single number between zero and two
//     )            # CLOSE NCG2
//     :            # A literal colon ':'
//     [0-5]\d      # A single number from 0 to 5 followed by any digit
//   )              # CLOSE NCG1
// $                # End of the string
export const timeRegex12 = /^(?:(?:\d|1[0-2]):[0-5]\d)$/;

function isEditing12Hors(newInput, caret, initialValue) {
  if (caret <= 1 || (caret === 2 && initialValue.length === 5)) {
    return true;
  }
  return caret === 2 && initialValue[0] === '1' && newInput <= 2;
}

function handleMinutesChange(newInput, newCaret, initialValue: string) {
  const [prevHours, prevMinutes] = initialValue.split(':');
  const [prevTenMinute, prevMinute] = prevMinutes.split('').map(Number);
  const len = initialValue.length;
  const minutesStart = len - 2;
  if (newCaret === len) {
    return [`${prevHours}:${prevTenMinute}${newInput}`, newCaret];
  }
  if (newCaret > len) {
    return [initialValue, newCaret];
  }
  if (newInput >= 6) {
    return [initialValue, newCaret - 1];
  }
  return [
    `${prevHours}:${newInput}${prevMinute}`,
    newCaret === minutesStart ? newCaret + 1 : newCaret,
  ];
}

function isValidHourHandling(first: number, second: number) {
  return first < 2 || (first === 2 && second < 4);
}

function isEditingHours(caret: number) {
  return caret <= 2;
}

export function handle12Edit({
  newInputValue,
  newInputCaret,
  newInput,
  prevTenHour,
  prevHour,
  prevMinutes,
  initialValue,
}: any) {
  if (isEditing12Hors(newInput, newInputCaret, initialValue)) {
    if (newInputCaret === 1) {
      if (newInput === 0) {
        newInputValue = initialValue;
        newInputCaret = 0;
      } else if (newInput === 1) {
        if (prevTenHour) {
          // 10| 11 | 12
          newInputValue = initialValue;
          newInputCaret = 1;
        } else if (prevHour < 3) {
          // 1 | 2
          newInputValue = `${newInput}${prevHour}:${prevMinutes}`;
        } else {
          newInputValue = `${newInput}:${prevMinutes}`;
        }
      } else {
        newInputValue = `${newInput}:${prevMinutes}`;
        newInputCaret += 1;
      }
    } else if (newInput >= 3) {
      newInputValue = `${newInput}:${prevMinutes}`;
    } else {
      newInputValue = `${
        prevTenHour === 0 ? prevHour : prevTenHour
      }${newInput}:${prevMinutes}`;
      newInputCaret += 1;
    }
    return [newInputValue, newInputCaret];
  }

  return handleMinutesChange(newInput, newInputCaret, initialValue);
}

export function handle24Edit({
  newInputValue,
  newInputCaret,
  newInput,
  prevTenHour,
  prevHour,
  prevMinutes,
  prevCaret,
  initialValue,
}: any) {
  if (isEditingHours(newInputCaret)) {
    const first = newInputCaret === 1 ? newInput : prevTenHour;
    const second = newInputCaret === 1 ? prevHour : newInput;
    if (isValidHourHandling(first, second)) {
      newInputValue = `${first}${second}:${prevMinutes}`;
    } else {
      newInputCaret = prevCaret;
    }
    return [newInputValue, newInputCaret];
  }
  return handleMinutesChange(newInput, newInputCaret, initialValue);
}

export function handle12Delete({
  newInputCaret,
  newInputValue,
  initialValue,
  prevCaret,
  prevHour,
  prevTenHour,
  prevMinutes,
}: any) {
  const delimeterStart = initialValue.length === 5 ? 2 : 1;
  if (delimeterStart >= newInputCaret) {
    // removing hours
    const newHours =
      initialValue.length === 5
        ? newInputCaret === 0
          ? prevHour || 12
          : prevTenHour
        : 12;
    newInputValue = `${newHours}:${prevMinutes}`;
  } else {
    // eslint-disable-next-line prefer-template
    newInputValue = `${initialValue.substring(
      0,
      newInputCaret,
    )}0${initialValue.substring(newInputCaret + 1)}`;
  }
  newInputCaret =
    delimeterStart === newInputCaret || delimeterStart === prevCaret
      ? prevCaret
      : newInputCaret;
  return [newInputValue, newInputCaret];
}

export function handle24Delete({
  newInputCaret,
  newInputValue,
  initialValue,
  prevCaret,
}: any) {
  // basically replace deleted value with 0 and move caret taking in account ':'
  if (newInputCaret === 2) {
    // eslint-disable-next-line prefer-template
    newInputValue = `${initialValue.substring(
      0,
      prevCaret,
    )}0${initialValue.substring(newInputCaret)}`;
  } else {
    // eslint-disable-next-line prefer-template
    newInputValue = `${initialValue.substring(
      0,
      newInputCaret,
    )}0${initialValue.substring(newInputCaret + 1)}`;
  }
  newInputCaret =
    newInputCaret === 2 || newInputCaret === 3 ? prevCaret : newInputCaret;
  return [newInputValue, newInputCaret];
}

export function get24Hour(hour: string, amPm: AmPm): string {
  const hourInt = parseInt(hour, 10);
  if (amPm === AmPm.AM) {
    return hourInt === 12 ? '00' : hour;
  }
  if (amPm === AmPm.PM) {
    return hourInt === 12 ? hour : `${hourInt + 12}`;
  }
  return hour;
}

export function from12ToSaveValue(s: string, amPm: AmPm) {
  const [hours, minutes] = s.split(':');
  let hoursInt = Number(hours);
  if (amPm === AmPm.PM) {
    hoursInt = hoursInt === 12 ? 12 : hoursInt + 12;
  }
  if (amPm === AmPm.AM) {
    hoursInt = hoursInt === 12 ? 0 : hoursInt;
  }
  return `${hoursInt.toString().padStart(2, '0')}:${minutes}`;
}

export function formatSaveValueTo12(s: string) {
  const [hours, minutes] = s.split(':').map(Number);
  if (hours < 12) {
    return `${hours === 0 ? 12 : hours}:${minutes} ${AmPm.AM}`;
  }

  return `${hours === 12 ? hours : hours - 12}:${minutes} ${AmPm.PM}`;
}

export function timeToMs(time: string) {
  const [hours, minutes] = time.split(':').map(Number);
  return (hours * 3600 + minutes * 60) * 1e3;
}

function prependParams(prevItems, newItems) {
  return [...newItems, ...prevItems];
}

function dedupeParams(params) {
  return params.filter(
    ({ name }, i, arr) =>
      i === arr.findIndex(({ name: nextName }) => name === nextName),
  );
}

function processFetchedParams(prevItems = [], newItems = []) {
  return dedupeParams(prependParams(prevItems, newItems));
}

export function addFetchedItems(
  tree: TreeItem | TreeItem[],
  item: TreeItem,
  subItems: TreeItem[],
  itemReplacer: (
    prevItems: TreeItem[],
    newItems: TreeItem[],
  ) => TreeItem[] = processFetchedParams,
) {
  return Array.isArray(tree)
    ? tree.map(branch => addFetchedItems(branch, item, subItems, itemReplacer))
    : tree.id === item.id
    ? {
        ...item,
        items: itemReplacer ? itemReplacer(tree.items, subItems) : subItems,
      }
    : {
        ...tree,
        ...(tree.items
          ? { items: addFetchedItems(tree.items, item, subItems, itemReplacer) }
          : {}),
      };
}

export function group<T>(
  items: T[] = [],
  cb: (item: T) => string | number,
): Record<string | number, T[]> {
  return items.reduce((acc, item) => {
    const key = cb(item);
    acc[key] = acc[key] ? acc[key].concat(item) : [item];
    return acc;
  }, {});
}

export function getChartLabelByType(type: AxisType) {
  switch (type) {
    case AxisType.Pressure:
      return 't567';
    case AxisType.Temperature:
      return 't123';
    case AxisType.Percentage:
      return 't868';
    case AxisType.Voltage:
      return 't869';
    case AxisType.Current:
      return 't870';
    case AxisType.Power:
      return 't871';
    case AxisType.Energy:
      return 't347';
    case AxisType.Frequency:
      return 't872';
    case AxisType.Light:
      return 't109';
    case AxisType.Concentration:
      return 't873';
    case AxisType.Flow:
      return 't874';
    case AxisType.Speed:
      return 't875';
    case AxisType.Acidity:
      return 't876';
    case AxisType.Date:
      return 't647';
    case AxisType.Time:
      return 't312';
    case AxisType.Other:
      return 't144';
    default:
      return '';
  }
}

export function getChartLabelFormat(type: AxisType, appUnitFormats) {
  const isBar = appUnitFormats[AxisType.Pressure] === '2';
  const isCelsius = appUnitFormats[AxisType.Temperature] === '4';
  const lightFormat = appUnitFormats[AxisType.Light];
  switch (type) {
    case AxisType.Pressure:
      return `{value}${isBar ? 'Bar' : 'PSI'}`;
    case AxisType.Temperature:
      return `{value}°${isCelsius ? 'C' : 'F'}`;
    case AxisType.Percentage:
      return '{value}%';
    case AxisType.Acidity:
      return '{value}ph';
    case AxisType.Speed:
      return '{value}ft/s';
    case AxisType.Flow:
      return '{value}gpm';
    case AxisType.Concentration:
      return '{value}ppm';
    case AxisType.Light:
      return `{value}${
        lightFormat === '5' ? '%' : lightFormat === '20' ? 'Footcandles' : 'Lux'
      }`;
    case AxisType.Frequency:
      return '{value}Hz';
    case AxisType.Energy:
      return '{value}kW-h';
    case AxisType.Power:
      return '{value}kW';
    case AxisType.Current:
      return '{value}Amp';
    case AxisType.Voltage:
      return '{value}V';
    case AxisType.Other:
    default:
      return '';
  }
}

export function getUnitType({ param: { digital, ptype } }): AxisType {
  if (digital === '1') {
    return AxisType.Binary;
  }
  switch (Number(ptype)) {
    case Ptype.PTYPE_F2PSI:
      return AxisType.Pressure;
    case Ptype.PTYPE_F2DEGF:
    case Ptype.PTYPE_F2DEGFD:
      return AxisType.Temperature;
    case Ptype.PTYPE_F2PCT: // DB_FLOAT 2 byte: percent
      return AxisType.Percentage;
    case Ptype.PTYPE_F2VOLT: // DB_FLOAT 2 byte: Volts
      return AxisType.Voltage;
    case Ptype.PTYPE_F2AMP: // DB_FLOAT 2 byte: Amps
      return AxisType.Current;
    case Ptype.PTYPE_F2KW: // DB_FLOAT 2 byte: KiloWatts
    case Ptype.PTYPE_F4KW: // DB_FLOAT 4 byte: KiloWatts
      return AxisType.Power;
    case Ptype.PTYPE_F2KWH: // DB_FLOAT 2 byte: KiloWatt Hours
    case Ptype.PTYPE_F4KWH:
      return AxisType.Energy;
    case Ptype.PTYPE_F2HZ: // DB_FLOAT 2 byte: Hertz
      return AxisType.Frequency;
    case Ptype.PTYPE_F2FC:
      return AxisType.Light;
    case Ptype.PTYPE_F2PPM: // ppm
      return AxisType.Concentration;
    case Ptype.PTYPE_F2GPM: // gpm
      return AxisType.Flow;
    case Ptype.PTYPE_F2FPS: // ft/s
      return AxisType.Speed;
    case Ptype.PTYPE_F2PH: // ph
      return AxisType.Acidity;
    case Ptype.PTYPE_FLOAT2: // DB_FLOAT 2 byte: generic small float
    case Ptype.PTYPE_FLOAT4:
    default:
      return AxisType.Other;
  }
}

export function getHistoryObjId(
  deviceId: string,
  i: number,
  historyMarker = HISTORY_MARKER,
) {
  return `${deviceId}_${i}${historyMarker}`;
}

export function isHistoryObj({ id = '' } = {}) {
  return id.endsWith(HISTORY_MARKER);
}

export function formatHistoryAxisLabel(dateTimeFormat: string) {
  return dateTimeFormat.slice(0, dateTimeFormat.indexOf('('));
}

export function getAxisTitle(data: any) {
  const dateTime = new Date(data)
    .toString()
    .slice(new Date(data).toString().indexOf('G'))
    .split(' ');
  return `(${dateTime[0]})`;
}

export function getUnit(ptype: any, appUnitFormats) {
  const isBar = appUnitFormats[AxisType.Pressure] === '2';
  const isCelsius = appUnitFormats[AxisType.Temperature] === '4';
  const lightFormat = appUnitFormats[AxisType.Light];

  switch (Number(ptype)) {
    case Ptype.PTYPE_F2PSI:
      return isBar ? 'Bar' : 'PSI';
    case Ptype.PTYPE_F2DEGF:
    case Ptype.PTYPE_F2DEGFD:
      return isCelsius ? 'C' : 'F';
    case Ptype.PTYPE_F2PCT: // DB_FLOAT 2 byte: percent
      return '%';
    case Ptype.PTYPE_F2VOLT: // DB_FLOAT 2 byte: Volts
      return 'V';
    case Ptype.PTYPE_F2AMP: // DB_FLOAT 2 byte: Amps
      return 'Amp';
    case Ptype.PTYPE_F2KW: // DB_FLOAT 2 byte: KiloWatts
    case Ptype.PTYPE_F4KW: // DB_FLOAT 4 byte: KiloWatts
      return 'kW';
    case Ptype.PTYPE_F2KWH: // DB_FLOAT 2 byte: KiloWatt Hours
    case Ptype.PTYPE_F4KWH:
      return 'kW-h';
    case Ptype.PTYPE_F2HZ: // DB_FLOAT 2 byte: Hertz
      return 'Hz';
    case Ptype.PTYPE_F2FC:
      return lightFormat === '5'
        ? '%'
        : lightFormat === '20'
        ? 'Footcandles'
        : 'Lux';
    case Ptype.PTYPE_F2PPM: // ppm
      return 'ppm';
    case Ptype.PTYPE_F2GPM: // gpm
      return 'gpm';
    case Ptype.PTYPE_F2FPS: // ft/s
      return 'ft/s';
    case Ptype.PTYPE_F2PH: // ph
      return 'ph';
    case Ptype.PTYPE_FLOAT2: // DB_FLOAT 2 byte: generic small float
    case Ptype.PTYPE_FLOAT4:
    default:
      return '';
  }
}

export function formatHistoryTitle(historyItem) {
  const parentDeviceName = historyItem?.parentDeviceName;
  const itemName = historyItem?.name;

  if (!parentDeviceName) {
    return itemName;
  }

  if (itemName.startsWith(parentDeviceName) && itemName !== parentDeviceName) {
    const replacedName = itemName.replace(parentDeviceName, '').trim();
    return replacedName.startsWith(':')
      ? replacedName.substring(1).trim()
      : replacedName;
  }
  return itemName;
}

export function setParentDeviceFields(historyData: any[]) {
  if (!historyData.length) return historyData;

  return historyData.map(parent => {
    if (parent.items) {
      const items = parent.items.map(child => {
        const a = setParentDeviceFields(child);
        return {
          ...a,
          parentDeviceName: parent.name,
          parentDeviceId: parent.id,
        };
      });
      return { ...parent, items };
    }
    return { ...parent };
  });
}

export const getMinMaxAvg = (data: ChartData['data']) => {
  const legendData = data.reduce(
    (acc, { dataValue }) => {
      if (dataValue < acc.min) acc.min = dataValue;
      if (dataValue > acc.max) acc.max = dataValue;

      if (!isNaN(dataValue)) {
        acc.sum += dataValue;
        acc.amount += 1;
      } else {
        dataValue = 0;
      }

      return acc;
    },
    { min: +Infinity, max: -Infinity, sum: 0, amount: 0 },
  );
  const legendMinMaxAvg: Legend = {
    min: legendData.min,
    max: legendData.max,
    avg: getAvg(legendData.sum, legendData.amount),
  };
  return legendMinMaxAvg;
};

export const getAvg = (sum: number, amount: number) => {
  const avg = +(sum / amount).toFixed(2);
  return isNaN(avg) ? 0 : avg;
};
